import React from 'react'
import Register from '../../../containers/Register/Register'
import './RegisterBox.css'


const registerBoxPopUp = (props) => {

  return (
    <div className='RegisterBoxPopUp'>
      <Register
        onLoginClicked={props.onLoginClicked}
        callback={props.callback}
      />
    </div>
  )
}

export default registerBoxPopUp