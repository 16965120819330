import React, {Component} from 'react'
import {connect} from 'react-redux'
import Brand from '../../components/Dashboard/Brand/Brand'
import Tickets from '../../components/Dashboard/Tickets/Tickets.js'
import Loader from '../../components/UI/Loader/Loader.js'
import {getSummaryList, combineComplaintFields} from '../../utils/utils.js'
import * as action from '../../store/actions/index.js'
import * as constant from '../../utils/constant.js'
import * as constantDashboard from './constant.js'

class Dashboard extends Component {

  componentDidMount() {
    this.props.clearRedirect()
    this.props.clearComplaint()

    if (this.props.contactId !== null) {
      this.props.onGetTicketList(this.props.contactId, this.props.sessionID)
    }
  }

  componentDidUpdate() {

    if ((this.props.contactId !== null) && (!this.state.alreadyGetTicketList)) {
      this.props.onGetTicketList(this.props.contactId, this.props.sessionID)
      this.setState({
        alreadyGetTicketList: true
      })
    }

    if (this.props.ticketid !== '' ) {
      this.onTicketDetailReceived()
    }

    if (this.props.error !== null && this.state.loading) {
      this.props.clearComplaint()
      this.setState({
        loading: false,
      })
    }
  }

  state = {
    loading: false,
    alreadyGetTicketList: false,
    currentImageIndex: 0,
  }

  onTicketDetailReceived = () => {
    const ticketDetail = this.props.ticketList.filter(ticket => {
      return ticket.ticketid === this.props.ticketid
    })[0]

    if (ticketDetail.status_th === constant.TICKET_STATUS_OPEN) {
      this.reloadCheckpointData(combineComplaintFields(this.props))
      return
    }

    this.props.history.push('/ticket_summary')
  }

  reloadCheckpointData = (ticket) => {

    if (ticket.sufferer === '' || ticket.productname === '' || ticket.productprice === '' || ticket.purchase === '') {
      this.props.history.push('/purchase')

    } else if (ticket.problem === '' || ticket.damage === '') {
      this.props.history.push('/problem')

    } else if (ticket.request === '') {
      this.props.history.push('/request')

    } else {
      this.props.history.push('/summary')
    }
  }

  onStartComplaintButtonCicked = () => {
    this.props.history.push('/accept')
  }

  onTicketClicked = (ticketID) => {
    this.setState({
      loading: true
    })
    this.props.onGetTicketDetail(ticketID, this.props.sessionID)
  }

  onNextImageClicked = () => {
    const len = window.env.dashboardImages.length
    const currentIndex = this.state.currentImageIndex

    let newIndex = currentIndex + 1
    if (newIndex > len - 1) {
      newIndex = 0
    }

    this.setState({
      currentImageIndex: newIndex
    })

  }

  onPrevImageClicked = () => {
    const len = window.env.dashboardImages.length
    const currentIndex = this.state.currentImageIndex

    let newIndex = currentIndex - 1
    if (newIndex < 0) {
      newIndex = len - 1
    }

    this.setState({
      currentImageIndex: newIndex
    })


  }


  render() {
    return (
      <div className = "container">
        <div className="row">
          <div className="col-12 col-complaint">
            <Brand 
              imgSrc={window.env.dashboardImages[this.state.currentImageIndex]}
              clicked = {this.onStartComplaintButtonCicked} 
              txtBrand={constantDashboard.BRAND_TITLE}
              onNextImageClicked={this.onNextImageClicked}
              onPrevImageClicked={this.onPrevImageClicked}
            />
            <Tickets
              ticketList = {this.props.ticketList}
              onClick = {this.onTicketClicked}
            />
	          <Loader show = {(this.state.loading || this.props.loading) && this.props.error === null} />
          </div>
        </div>
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    sessionID: state.session.sessionID,
    contactId: state.user.contactId,
    ticketList: state.complaint.ticketList,
    ticketid: state.complaint.ticketid,
    loading: state.complaint.loading,
    purchaseList: state.complaint.purchaseList,
    problemList: state.complaint.problemList,
    requestList: state.complaint.requestList,
    error: state.complaint.error,
    ...state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearRedirect: () => dispatch(action.clearRedirect()),
    clearComplaint: () => dispatch(action.clearSummaryComplaint()),
    onGetTicketList: (contactId, sessionID) => dispatch(action.getTicketList(contactId, sessionID)),
    onGetTicketDetail: (ticketID, sessionID) => dispatch(action.getTicketDetail(ticketID, sessionID)),
    onGetPurchaseList: () => dispatch(action.getPurchaseList()),
    onGetProblemList: (subcate2) => dispatch(action.getProblemList(subcate2)),
    onGetRequestList: () => dispatch(action.getRequestList()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
