import React from 'react'
import DatePicker from 'material-ui/DatePicker'
import TextForm from '../../../components/UI/Forms/TextForm/TextForm.js'
import SelectForm from '../../../components/UI/Forms/SelectForm/SelectForm.js'
import PriceForm from '../../../components/UI/Forms/PriceForm/PriceForm.js'
import ToggleSwitchForm from '../../UI/Forms/ToggleSwitchForm/ToggleSwitchForm.js'

const purchase = (props) => {

  let webForm = null
  if (props.checkWeb) {
    webForm = (
      <TextForm
        id = 'website'
        label = "Website Address"
        placeholder = "website url" 
        value = {props.fields.website}
        onChange = {props.onChange}
        error = {props.errors.website}
      />
    )
  }

  let storeForm = null
  if (props.checkStore) {
    storeForm = (
      <TextForm
        id = "store"
        label = "สถานที่ซื้อสินค้า/บริการ" 
        placeholder = "ชื่อสาขาร้านค้า/องค์กร/หน่วยงาน" 
        value = {props.fields.store}
        onChange = {props.onChange}
        error = {props.errors.store}
      />
    )
  }

  let relationSelectForm = null
  if (props.fields.delegate) {
    relationSelectForm = (
      <SelectForm
        id = "relation"
        label = "ความเกี่ยวข้องกับผู้ร้อง"
        value = {props.fields.relation}
        onChange = {props.onChange}
        options = {props.relationOptions}
        error = {props.errors.relation}
        selected={props.fields.relation[0] | ''}
      />
    )
  }

  return (
    <div className="container">

      <TextForm
        id = "sufferer"
        label = "ชื่อ-นามสกุล ผู้เสียหาย"
        placeholder = "ชื่อ-นามสกุล" 
        value = {props.fields.sufferer}
        onChange = {props.onChange}
        error = {props.errors.sufferer}
      />

      <ToggleSwitchForm
        id = "delegate"
        label = "ผู้แจ้งเป็นผู้ร้องเรียนแทน"
        checked = {props.fields.delegate}
        onClick = {props.onDelegateToggleClicked}
      />

      {relationSelectForm}

      <TextForm
        id = "productname"
        label = "ชื่อสินค้า/บริการ"
        placeholder = "ยี่ห้อหรือรุ่นของสินค้าที่ซื้อ / ชื่อบริการที่ใช้" 
        value = {props.fields.productname}
        onChange = {props.onChange}
        error = {props.errors.productname}
      />

      <SelectForm
        id = "payment"
        label = "วิธีการชำระเงิน"
        value = {props.fields.payment}
        onChange = {props.onChange}
        options = {props.paymentOptions}
        error = {props.errors.payment}
      />

      <PriceForm
        id = "productprice"
        label = "มูลค่าสินค้า/บริการ"
        placeholder="มูลค่าที่ซื้อสินค้าหรือบริการ" 
        value = {props.fields.productprice}
        onChange = {props.onChange}
        error = {props.errors.productprice}
      />

      <SelectForm
        id = "purchase"
        label = "ช่องทางการซื้อสินค้า/บริการ"
        value = {props.fields.purchase}
        onChange = {props.onChange}
        options = {props.purchaseOptions}
        error = {props.errors.purchase}
      />
      {webForm}
      {storeForm}

      <div className="form-group row">
        <label 
          className="col-12 col-sm-12 col-form-label">
          วันที่ซื้อสินค้า/เกิดเหตุการณ์<span className="font-red"> *</span>
        </label>
        <div className="col-12 col-sm-12">
          <DatePicker 
            hintText="Date" 
            value={new Date(props.fields.purchasedate)} 
            onChange={props.onDateChange}  
            required/>
        </div>
      </div>


    </div>
  )
}

export default purchase
