import React, {Component} from 'react'
import Bar from './Bar/Bar.js'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {combineComplaintFields} from '../../utils/utils.js'
import * as action from '../../store/actions/index.js' 
import * as constant from '../../utils/constant.js'
import {message } from 'antd'
import UpdateContactPopUp from '../../components/Complaint/Summaries/UpdateContactPopUp/UpdateContactPopUp.js'
import Loader from '../../components/UI/Loader/Loader.js'
import Backdrop from '../../components/UI/BackDrop/BackDrop.js'
import LoginBoxPopUp from './LoginBox/LoginBox.js'
import RegisterBoxPopUp from './RegisterBox/RegisterBox.js'

const SAVE_FOR_LATER = 'SAVE_FOR_LATER'
const SUBMIT_CARD = 'SUBMIT_CARD'

class StepBar extends Component {

  componentDidUpdate() {
    if (this.props.complaint.saveRedirect) {
      this.props.history.push('/dashboard') 
    }else{
      if (this.props.complaint.nextAds) {
        this.props.history.push('/Ads') 
      }
    }
  }

  state = {
    showContactUpdate: false,
    showLoginPopUp: false,
    showRegisterPopUp: false,
    updateContactData: {
      email: this.props.email || '',
      phone: this.props.phone || '',
    },
    errors: {
      email: '',
      phone: '',
    },
    action: ''
  }

  onSaveForLaterButtonClicked = () => {

    if (!this.props.isLogin) {
      this.setState({
        showLoginPopUp: true,
        action: SAVE_FOR_LATER,
      })
      return
    }

    if (!this.havePhoneOrEmail()) {
      this.setState({
        showContactUpdate: true,
        action: SAVE_FOR_LATER,
      })
    } else {
      this.onSaveLater()
    }

  }

  havePhoneOrEmail = () => {
    return this.props.phone || this.props.email
  }

  onSaveLater = () => {
    const props = this.props
    const fields = combineComplaintFields(props)
    message.success('เรื่องร้องเรียนของท่าน ยังไม่ได้ส่งเรื่องไปยังมูลนิธิเพื่อผู้บริโภค ท่านสามารถเลือกเรื่องร้องเรียนจากหน้าหลัก เพื่อแก้ไขได้ในภายหลัง',10)
    this.props.saveForLater(fields, this.props.session.sessionID)
  }

  onBackdropClicked = () => {
    this.setState({
      showContactUpdate: false,
      showLoginPopUp: false,
      showRegisterPopUp: false,
      updateContactData: {
        email: this.props.email || '',
        phone: this.props.phone || '',
      },
      errors: {
        email: '',
        phone: '',
      },
      action: '',
    })
  }

  onSubmitButtonClicked = () => {
    if (!this.props.isLogin) {
      this.setState({
        showLoginPopUp: true,
        action: SUBMIT_CARD,
      })
      return
    }

    if (!this.havePhoneOrEmail()) {
      this.setState({
        showContactUpdate: true,
        action: SUBMIT_CARD,
      })
    } else {
      this.callSubmitAPI()
    }
  }

  onUpdateContactChange = (event, id) => {
    let updateContactData = this.state.updateContactData
    updateContactData[id] = event.target.value

    let errors = this.state.errors
    errors[id] = ''

    this.setState({
      updateContactData,
      errors,
    })
  }

  onUpdateContactClick = () => {

    let phoneError = ''
    if (!this.state.updateContactData.phone || this.state.updateContactData.phone === '') {
      phoneError = 'กรุณากรอกเบอร์มือถือของคุณ'
    }
    let emailError = ''
    if (!this.state.updateContactData.email || this.state.updateContactData.email === '') {
      emailError = 'กรุณากรอกอีเมล์ของคุณ'
    }

    if (emailError !== '' && phoneError !== '') {
      this.setState({
        errors: {
          email: emailError,
          phone: phoneError,
        }
      })
      return
    }

    this.callUpdateContactAPI()
  }

  callSubmitAPI = () => {
    const props = this.props
    const fields = combineComplaintFields(props)
    message.success('เรื่องร้องเรียนของท่าน ทางมูลนิธิเพื่อผู้บริโภค ได้รับเรื่องร้องเรียนเป็นที่เรียบร้อยแล้ว และจะติดต่อกลับไปให้เร็วที่สุด',10)    
    this.props.submitCard(fields, this.props.session.sessionID)
  }


  callUpdateContactAPI = () => {
    const email = this.state.updateContactData.email
    const phone = this.state.updateContactData.phone
    const contactID = this.props.contactId
    const sessionID = this.props.sessionId

    const action = this.state.action

    const callback = () => {
      this.setState({
        showContactUpdate: false
      })
      if (action == SUBMIT_CARD) {
        this.callSubmitAPI()
      } else if (action == SAVE_FOR_LATER) {
        this.onSaveLater()
      }
    }
    this.props.updateContact(contactID, email, phone, sessionID, callback)
  }

  onSignUpClicked = () => {
    this.setState({
      showLoginPopUp: false,
      showRegisterPopUp: true,
    })
  }

  signupCallback = () => {
    this.setState({
      showRegisterPopUp: false,
    })
    if (this.state.action === SAVE_FOR_LATER) {
      this.onSaveForLaterButtonClicked()
      return
    }

    if (this.state.action === SUBMIT_CARD) {
      this.onSubmitButtonClicked()
      return
    }
  }

  onLoginClicked = () => {
    this.setState({
      showLoginPopUp: true,
      showRegisterPopUp: false,
    })
  }

  loginCallback = () => {
    this.setState({
      showLoginPopUp: false,
    })
    if (this.state.action === SAVE_FOR_LATER) {
      this.onSaveForLaterButtonClicked()
      return
    }

    if (this.state.action === SUBMIT_CARD) {
      this.onSubmitButtonClicked()
      return
    }
  }

  render() {

    let saveLaterButton = null
    if (this.props.saveAble && !this.props.isReadOnly) {
      saveLaterButton = (
        <button 
          type="button" 
          className="btn btnback float-left" 
          onClick={this.onSaveForLaterButtonClicked}>
          บันทึกเพื่อแก้ไขภายหลัง
        </button>	
      )
    }
    let submitButton = null
    if(this.props.header == constant.SUMMARY_HEADER && !this.props.isReadOnly){
      submitButton = (
        <button 
          type="button" 
          className="btn btn-primary float-right" 
          onClick={this.onSubmitButtonClicked}>
          ส่งเรื่อง
        </button>	
      )
    }

    let buttonName = ''
    if (this.state.action === SAVE_FOR_LATER) {
      buttonName = 'บันทึกเพื่อแก้ไขภายหลัง'
    } else if (this.state.action === SUBMIT_CARD) {
      buttonName = 'ส่งเรื่อง'
    }

    const updateContactPopUp = (
      <UpdateContactPopUp
        onChange={this.onUpdateContactChange}
        updateContactData={this.state.updateContactData}
        onClick={this.onUpdateContactClick}
        buttonName={buttonName}
        errors={this.state.errors}
      />
    )


    const loginBoxPopUp = (
      <LoginBoxPopUp
        onSignUpClicked={this.onSignUpClicked}
        callback={this.loginCallback}
      />
    )

    const registerBoxPopUp = (
      <RegisterBoxPopUp
        onLoginClicked={this.onLoginClicked}
        callback={this.signupCallback}
      />
    )

    return (
      <div className="container font-gray">
        <div className="row">
          <div className="col-12 col-complaint">
            <Bar 
              header = {this.props.header}
              onBack = {this.props.onBack} 
              onNext = {this.props.onNext}
              enable = {this.props.enable}
              isReadOnly = {this.props.isReadOnly}
              hasSubmit = {submitButton != null}
            />
            <br/>
            {this.props.children}
            <br/>
            {saveLaterButton}
            {submitButton}
            {this.state.showContactUpdate?updateContactPopUp:null}
            {this.state.showLoginPopUp?loginBoxPopUp: null}
            {this.state.showRegisterPopUp?registerBoxPopUp: null}
            <Loader show = {this.props.complaint.loading || this.props.user.loading} />
            <Backdrop 
              show={this.state.showContactUpdate || this.state.showLoginPopUp || this.state.showRegisterPopUp}
              clicked={this.onBackdropClicked}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    email: state.user.email,
    phone: state.user.phone,
    contactId: state.user.contactId,
    sessionId: state.session.sessionID,
    isLogin: state.user.contactId !== null,
    ...state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveForLater : (fields, sessionID) => dispatch(action.saveForLater(fields, sessionID)),
    submitCard : (fields, sessionID) => dispatch(action.submitCard(fields, sessionID)),
    updateContact : (contactId, email, phone, sessionID, callback) => dispatch(action.updateContact(contactId, email, phone, sessionID, callback)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StepBar))

