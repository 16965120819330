import React from 'react'
import { Link } from 'react-router-dom'
import './Brand.css'

import HeroSlider from '../../UI/HeroSlider/HeroSlider'


const Brand = (props) => {


  return (
    <div className="card card-makecomplaint">
      <div >
        <HeroSlider />
        <button 
          type="button" 
          className="btn btn-outline-primary btn-lg btn-blocks btn-complaint"
          onClick = {props.clicked}
        >เริ่มการร้องเรียน</button>
      </div>
    </div>
  )
}
export default Brand

/*
 <Link to={'/search'}>
  <button type="button" className="btn btn-outline-primary btn-lg btn-blocks btn-complaint">เริ่มการร้องเรียน</button>
  </Link>
   <Link to={'/search'}><button type="button" className="btn btn-outline-primary btn-lg btn-blocks btn-complaint">เริ่มการร้องเรียน</button></Link>
   
   <Link to="/conditions"><p className="card-text">** <u>เงื่อนไขการร้องทุกข์ออนไลน์</u></p></Link>


 */
