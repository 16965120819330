import React, {Component} from 'react'
import Modal from '../../components/UI/Modal/Modal.js'
import * as constant from '../../utils/constant.js'

const withErrorHandler = (WrapperComponent, axios) => {

  return class extends Component {

    state = {
      error: null
    }

    componentWillMount() {
      this.requestInterceptor = axios.interceptors.request.use(req => {
        this.setState({error: null})
        return req
      })

      this.responseInterceptor = axios.interceptors.response.use(
        response=> this.checkResponseStatus(response),
        error => {
          error.message = constant.CONNECTION_ERROR
          this.setState({error: error})
        }
      )
    }

    componentWillUnmount() {
      axios.interceptors.request.eject(this.requestInterceptor)
      axios.interceptors.response.eject(this.responseInterceptor)
    }

    checkResponseStatus = (response) => {
      if (response.status === 200) {
        return response
      }
      this.setState({
        error: {
          message: constant.API_ERROR
        }
      })
    }

    errorConfirmedHandler = () => {
      this.setState({error: null})
    }

    render() {
      return (
        <div>
          <Modal error={this.state.error} show = {this.state.error} modalClosed = {this.errorConfirmedHandler}>
            {this.state.error? this.state.error.message : null}
          </Modal>
          <WrapperComponent {...this.props} />
        </div>
      )
    }
  }
}


export default withErrorHandler
