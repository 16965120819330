import React, {Component} from 'react'
import LoginCard from '../../components/LoginCard/LoginCard'
import * as actions from '../../store/actions/index'
import {connect} from 'react-redux'
import Loader from '../../components/UI/Loader/Loader'
import * as constant from './constant.js'
import ForgetPasswordPopup from './ForgetPasswordPopup/ForgetPasswordPopup'
import Backdrop from '../../components/UI/BackDrop/BackDrop.js'
import { relativeTimeThreshold } from 'moment'

class Login extends Component {

  state = {
    errors: {
      email:'',
      password: ''
    },
    user: {
      email: '',
      password: ''
    },
    showForgetPasswordPopup: false,
    forgetPasswordEmail: '',
    showForgetPasswordSuccess: false,
    showForgetPasswordError: false,

  }  

  componentDidUpdate() {
    if (this.props.contactId != null) {
      if (this.props.redirectAfterLogin) {
        window.location.replace('/')
      }

      if (this.props.callback) {
        this.props.callback()
      }
    }
  }
  
  onUserChangeHandler = (event) => {
    let newUser = this.state.user
    newUser[event.target.name] = event.target.value

    this.setState({
      user: newUser
    })
  }

  onLoginSubmitted = (event) => {
    event.preventDefault()
    if (this.checkFieldsHaveNoError()) {
      this.props.onLogin(this.state.user.email, this.state.user.password, this.props.sessionID)
    }
  }

  checkFieldsHaveNoError = () => {
    let errors = Object.assign({}, this.state.errors)
    errors = {}
    this.setState({
      errors,
    })


    if(this.state.user.email.trim().length === 0){
      errors.email = constant.LOGIN_EMAIL_WARN
    }

    if(this.state.user.password.trim().length === 0){
      errors.password = constant.LOGIN_PASSWORD_WARN
    }

    if (JSON.stringify(errors) !== '{}') {
      this.setState({
        errors,
      })
      return false
    }else{
      return true
    }

    //return true
  }
  
  /**
   * Init FB object and check Facebook Login status
   */
  initializeFacebookLogin = () =>{
    this.FB = window.FB
    this.checkLoginStatus()
  }

  /**
   * Check login status
   */
  checkLoginStatus = () =>{
    window.FB.getLoginStatus(this.facebookLoginHandler)
  }

  /**
   * Check login status and call login api is user is not logged in
   */
  facebookLogin = (event) =>{
    event.preventDefault()
    document.addEventListener('FBObjectReady', this.initializeFacebookLogin)
    if (!window.FB) return
    window.FB.getLoginStatus(response => {
      window.FB.login(this.facebookLoginHandler, {scope: 'public_profile,email'})
    }, )
  }

  /**
   * Handle login response
   */
  facebookLoginHandler = (response) =>{
    if (response.status === 'connected') {
      //"name,"email","birthday","id":"1893148020739793"
      window.FB.api('/me?fields=name,email,birthday', userData => {
        let result = {
          response:response,
          user: userData
        }
        this.onFacebookLogin(true, result)
      })
    } else {
      this.onFacebookLogin(false)
    }
  }
  onFacebookLogin = (loginStatus, resultObject) =>{
    var errors = this.state.errors
    this.props.facebookLogin(resultObject.user.email, resultObject.user.name,resultObject.user.id)    
  }

  onBackdropClicked = () => {
    this.setState({
      showForgetPasswordPopup: false,
    })
    this.props.forgetPasswordClear()
  }

  onForgetPasswordLinkClicked = () => {
    this.setState({
      showForgetPasswordPopup: true,
    })
  }

  onForgetPasswordEmailChanged = (event) => {
    this.setState({
      forgetPasswordEmail: event.target.value
    })
  }

  onForgetPasswordButtonClicked = () => {
    this.props.forgetPassword(this.state.forgetPasswordEmail, this.props.sessionID)
  }


  render() {

    let forgetPasswordEmail = null
    if (this.state.showForgetPasswordPopup) {
      forgetPasswordEmail = (
        <ForgetPasswordPopup
          email={this.state.forgetPasswordEmail}
          onChange={this.onForgetPasswordEmailChanged}
          showSuccess={this.props.forgetPasswordSuccess === true}
          showError={this.props.forgetPasswordError !== null}
          onForgetPasswordButtonClicked={this.onForgetPasswordButtonClicked}
        />
      )
    }

    return (

      <div>
        <LoginCard 
          errors = {this.state.errors}
          user = {this.state.user}
          loginError = {this.props.loginError}
          manualLogin = {this.onLoginSubmitted}
          onChange = {this.onUserChangeHandler}
          onFacebookLoginClick = {this.facebookLogin}
          onForgetPasswordLinkClicked = {this.onForgetPasswordLinkClicked}
          {...this.props}
        />
        <Loader show = {this.props.loading}/>
        {forgetPasswordEmail}
        <Backdrop 
          show={this.state.showForgetPasswordPopup}
          clicked={this.onBackdropClicked}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    sessionID: state.session.sessionID,
    username: state.user.username,
    contactId: state.user.contactId,
    loginError: state.user.loginError,
    loading: state.user.loading,
    forgetPasswordError: state.user.forgetPasswordError,
    forgetPasswordSuccess: state.user.forgetPasswordSuccess,
    // isHaveData: state.user.isHaveData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (email, password, sessionID) => dispatch(actions.login(email, password, sessionID)),
    facebookLogin: (email,fbuser,fbid) => dispatch(actions.loginFacebook(email,fbuser,fbid)),
    forgetPassword: (email, sessionID) => dispatch(actions.forgetPassword(email, sessionID)),
    forgetPasswordClear: () => dispatch(actions.forgetPasswordClear()),
    // facebookRegister: (email,fbuser,fbid) => dispatch(actions.loginFacebookRegister(email,fbuser,fbid))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
