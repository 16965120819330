import React from 'react'

const priceForm = (props) => {
  let showError = 'none'  
  if (props.error) {
    showError = ''
  }

  return (
    <div className="form-group row">
      <label className="col-12 col-sm-12 col-form-label">
        {props.label} 
      </label>
      <div className="col-12 col-sm-12">
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text">฿</span>
          </div>
          <input 
            type="number" 
            className="form-control" 
            id={props.id}
            placeholder={props.placeholder}
            value={props.value} 
            onChange={props.onChange} 
            required/>
        </div>
      </div>
      <div className="col-12 col-sm-12">
        <p id='err_productprice' className="font-red" style={{display: showError}}>โปรดกรอกข้อมูล</p>                    
      </div>
    </div>
  )
}

export default priceForm
