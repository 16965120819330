import React from 'react'
import './Summary.css'

const summary = (props) => {
  return (
    <div className = "SummaryText">
      {props.title + ' : '}<span className = "SummarySpan">{props.value}</span>
    </div>
  )
}

export default summary

//{props.title + ' : '}<span className = "text-color">{props.value}</span>
