import React, {Component} from 'react'
import {connect} from 'react-redux'
import StepBar from '../../../hoc/StepBar/StepBar.js'
import Account from '../../../components/Complaint/Account/Account'
import * as action from '../../../store/actions/index'
import Loader from '../../../components/UI/Loader/Loader.js'
import * as constant from '../../../utils/constant.js'

class AccountPage extends Component {

  state = { 
    tempAccount : '',
    enable: false
  }

  handleChange = (event) => {
    let tempAccount  =  event.target.value

    this.setState({
      tempAccount: tempAccount,
      enable: tempAccount.length > 0
    })
  }

  onBack = () => {
    this.props.onSearchStop()
    this.props.history.push('search')
  }

  onNext = () => {
    this.props.history.push('/category') 
    this.props.onSelected({
      title: this.state.tempAccount,
      id: ''
    })
  }
 
  render() {
    return (
      <StepBar 
        header = {constant.SEARCH_HEADER}
        onBack = {this.onBack} 
        enable = {this.state.enable}
        onNext = {this.onNext}
      >
        <Account accountName={this.state.tempAccount} handleChange={this.handleChange}/> 
      </StepBar>

    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSearchStop: () => dispatch(action.searchStop()),
    onSelected: (selectedResult) => dispatch(action.searchSelected(selectedResult)),
  }
}

export default connect(null, mapDispatchToProps)(AccountPage)
