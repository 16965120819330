import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter} from 'react-router-dom'
import {createStore, applyMiddleware, compose, combineReducers} from 'redux'
import {Provider} from 'react-redux'
import thunk from 'redux-thunk'
import App from './App.js'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import sessionReducer from './store/reducers/session.js'
import userReducer from './store/reducers/user'
import registerReducer from './store/reducers/register'
import complaintReducer from './store/reducers/complaint.js'
import adsReducer from './store/reducers/ads.js'
import './index.css'
import 'antd/dist/antd.css'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const rootReducer = combineReducers( {
  session: sessionReducer,
  user: userReducer,
  register: registerReducer,
  complaint: complaintReducer,
  ads: adsReducer
})

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))

const app = (
  <Provider store = {store}>
    <MuiThemeProvider muiTheme = {getMuiTheme()}>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </MuiThemeProvider>
  </Provider>
)

ReactDOM.render(app, document.getElementById('root'))
