import React from 'react'

const Btn = (props) => {
   
  return (
    <div className="row">
      <div className="col-12">
        <button type="button" className="btn btnback float-left" onClick={props.backtomain} name="backtomain">กลับไปยังหน้าหลัก</button>
        <button type="button" className="btn btn-primary float-right" onClick={props.saveadspage}>บันทึกคำตอบ</button>
      </div>
    </div>
  )
}
export default Btn