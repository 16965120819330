import React, {Component} from 'react'
import {connect} from 'react-redux'
import StepBar from '../../../hoc/StepBar/StepBar.js'
import Search from '../../../components/UI/Forms/SearchForm/Search.js'
import BtnNewAcc from '../../../components/Complaint/Search/Content/BtnNewAcc'
import * as action from '../../../store/actions/index'
import Content from '../../../components/Complaint/Search/Content/Content.js'
import Loader from '../../../components/UI/Loader/Loader.js'
import * as constant from '../../../utils/constant.js'


class SearchPage extends Component {

  state = {
    typing: false,
    typingTimeout: 0,
    text: this.props.accountname
  }
  
  handleSearchChange = (event, {value}) => {

    this.props.onSearchStop()
    let text = value
    this.setState({typing: true, text: text})

    if (text.length === 0) {
      clearTimeout(this.state.typingTimeout)
      this.props.onSearchStop()
    }

    if(text.length >= 3){
      if (this.state.typingTimeout) {
        clearTimeout(this.state.typingTimeout)
      }  
  
      this.setState({
        typing: false,
        typingTimeout: setTimeout(() => {
          this.props.onSearch(text, this.props.sessionID)
        }, 1000),
      })
    }
  }

  handleResultSelect = (event, {result}) => {
    if (result.title !== constant.NO_RESULT_FOUND) {
      this.props.onSelected(result)
      this.setState({
        text: result.title
      })
    }
  }

  onBack = () => {
    this.props.history.push('accept')
  }

  onNext = () => {
    this.props.history.push('/category')
  }

  toAccount =() =>{
    this.props.history.push('account')
  }

  render() {
    return (
      <StepBar 
        header = {constant.SEARCH_HEADER}
        onBack = {this.onBack} 
        enable = {this.props.enable}
        onNext = {this.onNext}
      >
        <Search 
          handleResultSelect = {this.handleResultSelect} 
          handleSearchChange = {this.handleSearchChange}
          loading={this.props.searchLoading}
          results={this.props.results}
          value={this.state.text} 
        />
        <br></br>
        <br></br>
        <Content/>
        <br></br>
        <br></br>
        <BtnNewAcc addAccount = {this.state.addAccount} account={this.toAccount}/>
        <Loader show = {this.props.apiLoading} />
      </StepBar>
    )
  }
}

const mapStateToProps = state => {
  return {
    sessionID: state.session.sessionID,
    results : state.complaint.results,
    searchLoading : state.complaint.searchLoading,
    apiLoading: state.complaint.loading,
    enable : state.complaint.enable,
    accountname: state.complaint.accountname
  }
}
  
const mapDispatchToProps = dispatch => {
  return {
    onSearchStop: () => dispatch(action.searchStop()),
    onSearch: (text, sessionID) => dispatch(action.search(text, sessionID)),
    onSelected: (selectedResult) => dispatch(action.searchSelected(selectedResult)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage)
