// SESSION 
export const GET_SESSION_START = 'GET_SESSION_START'
export const GET_SESSION_SUCCESS = 'GET_SESSION_SUCCESS'
export const GET_SESSION_FAIL = 'GET_SESSION_FAIL'

// Login
export const CHECK_LOGIN = 'CHECK_LOGIN'
export const LOGOUT = 'LOGOUT'
export const LOGIN_START = 'LOGIN_START'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'


// FORGET PASSWORD
export const FORGET_PASSWORD_START = 'FORGET_PASSWORD_START'
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS'
export const FORGET_PASSWORD_FAIL = 'FORGET_PASSWORD_FAIL'
export const FORGET_PASSWORD_CLEAR = 'FORGET_PASSWORD_CLEAR'

// Register
export const REGISTER_START = 'REGISTER_START'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const CLEAR_REGISTER = 'CLEAR_REGISTER'

// SEARCH
export const SEARCH_START = 'SEARCH_START'
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS'
export const SEARCH_STOP = 'SEARCH_STOP'
export const SEARCH_SELECTED = 'SEARCH_SELECTED'

// subcate1
export const GET_SUBCATE1_START = 'GET_SUBCATE1_START'
export const GET_SUBCATE1_SUCCESS = 'GET_SUBCATE1_SUCCESS'
export const CLEAR_SUBCATE1 = 'CLEAR_SUBCATE1'

// subcate2 
export const SAVE_SUBCATE2 = 'SAVE_SUBCATE2'
export const GET_SUBCATE2_START = 'GET_SUBCATE2_START'
export const GET_SUBCATE2_SUCCESS = 'GET_SUBCATE2_SUCCESS'
//export const GET_SUBCATE2_FAIL = 'GET_SUBCATE2_FAIL'
export const CLEAR_SUBCATE2 = 'CLEAR_SUBCATE2'

// purchase 
export const GET_PURCHASE_LIST_START = 'GET_PURCHASE_LIST_START'
export const GET_PURCHASE_LIST_SUCCESS = 'GET_PURCHASE_LIST_SUCCESS'
export const CLEAR_PURCHASE_LIST = 'CLEAR_PURCHASE_LIST'
export const SAVE_PURCHASE_FORM = 'SAVE_PURCHASE_FORM'

// relation list
export const GET_RELATION_LIST_START = 'GET_RELATION_LIST_START'
export const GET_RELATION_LIST_SUCCESS = 'GET_RELATION_LIST_SUCCESS'

// problem
export const GET_PROBLEM_LIST_START = 'GET_PROBLEM_LIST_START'
export const GET_PROBLEM_LIST_SUCCESS = 'GET_PROBLEM_LIST_SUCCESS'
export const GET_PROBLEM_LIST_FAIL = 'GET_PROBLEM_LIST_FAIL'
export const CLEAR_PROBLEM_LIST = 'CLEAR_PROBLEM_LIST'
export const SAVE_PROBLEM_FORM = 'SAVE_PROBLEM_FORM'

// request 
export const GET_REQUEST_LIST = 'GET_REQUEST_LIST'
export const GET_REQUEST_LIST_START = 'GET_REQUEST_LIST_START'
export const GET_REQUEST_LIST_SUCCESS = 'GET_REQUEST_LIST_SUCCESS'
export const CLEAR_REQUEST_LIST = 'CLEAR_REQUEST_LIST'
export const SAVE_NEW_REQUEST = 'SAVE_NEW_REQUEST'

//submit
export const SAVE_OWNER = 'SAVE_OWNER'

// error
export const CALL_API_FAIL = 'CALL_API_FAIL'

// saveforlater
export const SAVE_FOR_LATER = 'SAVE_FOR_LATAR'
export const SAVE_FOR_LATER_START = 'SAVE_FOR_LATER_START'
export const SAVE_FOR_LATER_SUCCESS = 'SAVE_FOR_LATER_SUCCESS'

// ticketList
export const GET_TICKET_LIST = 'GET_TICKET_LIST'
export const GET_TICKET_LIST_START = 'GET_TICKET_LIST_START'
export const GET_TICKET_LIST_SUCCESS = 'GET_TICKET_LIST_SUCCESS'

// ticketDetail
export const GET_TICKET_DETAIL= 'GET_TICKET_DETAIL'
export const GET_TICKET_DETAIL_START = 'GET_TICKET_DETAIL_START'
export const GET_TICKET_DETAIL_SUCCESS = 'GET_TICKET_DETAIL_SUCCESS'

// submitCard
export const SUBMIT_CARD = 'SUBMIT_CARD'
export const SUBMIT_CARD_START = 'SUBMIT_CARD_START'
export const SUBMIT_CARD_SUCCESS = 'SUBMIT_CARD_SUCCESS'

//Ads
export const SAVE_ADS = 'SAVE_ADS'
export const SUBMIT_ADS = 'SUBMIT_ADS'
export const SUBMIT_ADS_START = 'SUBMIT_ADS_START'
export const SUBMIT_ADS_SUCCESS = 'SUBMIT_ADS_SUCCESS'
export const CLEAR_REDIRECT = 'CLEAR_REDIRECT'

// SUMMARY
export const SET_SUMMARY_COMPLAINT = 'SET_SUMMARY_COMPLAINT'
export const CLEAR_SUMMARY_COMPLAINT = 'CLEAR_SUMMARY_COMPLAINT'

// UPLOAD_IMAGE
export const SAVE_UPLOAD_IMAGE = 'SAVE_UPLOAD_IMAGE'

// Login Facebook
export const LOGIN_FACEBOOK_START = 'LOGIN_FACEBOOK_START'
export const LOGIN_FACEBOOK_SUCCESS = 'LOGIN_FACEBOOK_SUCCESS'
export const LOGIN_FACEBOOK_REGISTER_SUCCESS = 'LOGIN_FACEBOOK_REGISTER_SUCCESS'
export const LOGIN_FACEBOOK_NULL = 'LOGIN_FACEBOOK_NULL'

// UPLOAD IMAGE
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE'
export const UPLOAD_IMAGE_START = 'UPLOAD_IMAGE_START'
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS'

// Error
export const SET_ERROR = 'SET_ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'

// UpdateContact
export const UPDATE_CONTACT_START = 'UPDATE_CONTACT_START'
export const UPDATE_CONTACT_FAIL = 'UPDATE_CONTACT_FAIL'
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS'
