import React from 'react'
//import { Link} from 'react-router';
import {Link} from 'react-router-dom'

import { Card, CardText } from 'material-ui/Card'
//import TextField from 'material-ui/TextField';

import LoginForm from './LoginForm/LoginForm'
import './LoginCard.css'

const loginCard = (props) => {


  const bottom = (
    <div>
      <br/>
      <CardText style={{'padding': '7px'}} className="font-text inline" >หากยังไม่มีบัญชี ? <Link style={{'whiteSpace': 'nowrap'}} to={'/signup'}>สร้างบัญชี</Link>.</CardText>
      <div className='forget-password inline'>
        <Link style={{'whiteSpace': 'nowrap', 'fontSize': '14px'}} className='font-text' to={'#'} onClick={props.onForgetPasswordLinkClicked}>ลืมรหัสผ่าน?</Link>
      </div>
    </div>
  )
  const bottomPopUp = (
    <div>
      <br/>
      <CardText style={{'padding': '7px'}} className="font-text" >หากยังไม่มีบัญชี ? <Link to='#' onClick={props.onSignUpClicked}>สร้างบัญชี</Link>.</CardText>
      <div className='forget-password inline'>
        <Link className='font-text' to={'#'} onClick={props.onForgetPasswordLinkClicked}>ลืมรหัสผ่าน?</Link>
      </div>
    </div>
  )

  return (
    <Card className="container text-center" style={{width: '80%'}}>
 
      <LoginForm 
        {...props}
      />


      <p className="text-center font-text">หรือ</p>
      <button 
        className="btn btn-primary font-text" 
        onClick={props.onFacebookLoginClick}
      >เข้าสู่ระบบด้วย Facebook</button>
      {props.onSignUpClicked ? bottomPopUp: bottom}
    </Card>
  )
}

export default loginCard
/*
   *      <form action="/" onSubmit={props.onSubmit}>
        <br></br>
        <h2 className="jumbotron-heading font-text">เข้าสู่ระบบ</h2>
user = {props.user}
        errors = {props.errors}
        onChange = {props.onChange}
        onLogin = {props.onLogin}

        TextField
        TextField

        <div class="button-line font-text">
          <button type="submit" className="btn btn-primary">เข้าสู่ระบบ</button>
        </div>     

      </form>     
   *
   *
   *
   *
   *
   *
      <Card className="container text-center" style={{width: '80%'}}>
          <form action="/" onSubmit={this.processForm}>
            <br></br>
            <h2 className="jumbotron-heading font-text">เข้าสู่ระบบ</h2>

            {this.state.successMessage && <p className="success-message">{this.state.successMessage}</p>}
            {this.state.errors.summary && <p className="error-message">{this.state.errors.summary}</p>}

            <div class="field-line font-text">
              <TextField
                floatingLabelText="อีเมล"
                name="email"
                errorText={this.state.errors.email}
                onChange={this.changeUser}
                value={this.state.user.email}
              />
            </div>

            <div class="field-line font-text">
              <TextField
                floatingLabelText="รหัสผ่าน"
                type="password"
                name="password"
                onChange={this.changeUser}
                errorText={this.state.errors.password}
                value={this.state.user.password}
              />
            </div>
            <div class="button-line font-text">
              <button type="submit" className="btn btn-primary">เข้าสู่ระบบ</button>
            </div>     
          </form>      
          <div className="text-center font-text"><p>หรือ</p></div>
          <div>
            <button className="btn btn-primary font-text" onClick={this.facebookLogin}>เข้าสู่ระบบด้วย Facebook</button>
          </div >
          <CardText className="font-text" >หากยังไม่มีบัญชี ? <Link to={'/signup'}>สร้างบัญชี</Link>.</CardText>
        </Card>

*/
