import React, { Component } from 'react'
import {connect} from 'react-redux'
import Summaries from '../../../components/Complaint/Summaries/Summaries.js'
import * as constant from '../../../utils/constant.js'
import {getSummaryList, combineComplaintFields} from '../../../utils/utils.js'
import StepBar from '../../../hoc/StepBar/StepBar.js'
import Loader from '../../../components/UI/Loader/Loader'
import UpdateContactPopUp from '../../../components/Complaint/Summaries/UpdateContactPopUp/UpdateContactPopUp.js'
import './Summary.css'
import Backdrop from '../../../components/UI/BackDrop/BackDrop.js'
import {message } from 'antd'
import * as action from '../../../store/actions/index.js'

class SummaryPage extends Component {


  state = {
    summaryList: getSummaryList(combineComplaintFields(this.props)),
  }

  onBack = () => {
    this.props.history.push('/request')
  }
  

  render() {

    const updateContactPopUp = (
      <UpdateContactPopUp
        onChange={this.onUpdateContactChange}
        updateContactData={this.state.updateContactData}
        onClick={this.onUpdateContactClick}
      />
    )

    return (
      <StepBar
        header = {constant.SUMMARY_HEADER}
        onBack = {this.onBack}
        enable = {this.props.complaint.ownerid !== null}
        saveAble
      >
        <div className = "SummaryContainer">
          <p className = "SummaryTitle">
            {constant.SUMMARY_TITLE}
          </p>
          <Summaries summaryList = {this.state.summaryList}/>
        </div>
  
      </StepBar>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

export default connect(mapStateToProps)(SummaryPage)
