import * as actionTypes from './actionTypes'
import axios from '../../axios-vtiger'
import * as constant from '../constant.js'
import {sessionMiddleware} from '../actions/session.js'

export const register = (user, sessionID) => {
  return dispatch => {
    dispatch(registerStart())
    callRegister(dispatch, user, sessionID, 0)
  }
}

const callRegister = (dispatch, user, sessionID, count) => {
  const URI = '?function=NewContact&fname='+user.firstname+
    '&lname='+user.lastname+
    '&fbuser=&phone='+user.phone+
    '&email='+user.email+
    '&pass='+user.password+
    '&idcard='+user.idcard+
    '&sessionid='+sessionID
  axios.get(URI)
    .then(response => {

      if (response.data.code) {
        if (count < 1) {
          sessionMiddleware(dispatch, count, (session, count) => callRegister(dispatch, user, session, count))
        } else {
          dispatch(registerFail('session error'))
        }
        return
      }

      if (response.data.result === constant.RESPONSE_DUPPLICATED) {
        dispatch(registerFail(constant.EMAIL_ALREADY_USED))
      }
      else {
        dispatch(registerSuccess())
      }
    })
    .catch(error => {
      dispatch(registerFail(constant.REGISTER_FAIL))
    })

}

export const registerStart = () => {
  return {
    type: actionTypes.REGISTER_START
  }
}

export const registerSuccess = () => {
  return {
    type: actionTypes.REGISTER_SUCCESS
  }
}

export const registerFail = (error) => {
  return {
    type: actionTypes.REGISTER_FAIL,
    registerError: error
  }
}

export const clearRegister = () => {
  return {
    type: actionTypes.CLEAR_REGISTER
  }
}

