import * as actionTypes from '../actions/actionTypes'
import {getISODateString} from '../../utils/utils.js'
import {updateState} from '../utils'
import {getOwnerNameByID} from '../../utils/utils.js'
import * as constant from '../constant.js'

const initialState = {
  accountid: null,
  accountname: '',
  ticketid: '',
  ticketdate: getISODateString(new Date()),
  subcate1: '',
  subcate2: '',
  results : [],
  subcate2List: [],
  purchaseList: [],
  relationList: [],
  purchaseFields: {
    sufferer: '',
    productname: '',
    purchasedate: getISODateString(new Date()),
    payment : '',
    productprice : '',
    purchase : '',
    website : '',
    store : '',
    delegate: false,
    relation: '',
  },
  problemFields: {
    problem: '',
    damage: '',
    desc: ''
  },
  problemList: [],
  requestList: [],
  request: '',
  ownerid : constant.DEFAULT_OWNER_ID,
  ownername: '',
  loading: false,
  searchLoading: false,
  enable : false,
  saveRedirect: false,
  ticketList: null,
  nextAds: false,
  solution: '',
  imageFile: [
    {
      file: null,
      id: 'file_input_1'
    },
    {
      file: null,
      id: 'file_input_2'
    },
    {
      file: null,
      id: 'file_input_3'
    },
  ],
  error: null,
  isUpdate: false
}

const searchStart = (state, action) => {
  return updateState(state, {
    searchLoading: true, 
    enable: false,
  })
}

const searchSuccess = (state, action) => {
  return updateState(state, {
    searchLoading: false, 
    enable: false,
    results : action.results,
  })
}

const searchStop = (state, action) => {
  return updateState(state, {
    searchLoading: false, 
    enable: false,
    results : []
  })
}

const searchSelected = (state, action) => {
  return updateState(state, {
    accountid: action.accountid,
    accountname: action.accountname,
    enable: true
  })
}

const saveSubcate2 = (state, action) => {
  return updateState(state, {subcate2: action.subcate2})
}

const getSubcate2Start = (state, action) => {
  return updateState(state, {
    loading: true,
  })
}

const getSubcate2Success = (state, action) => {
  return updateState(state, {
    subcate2List: action.subcate2List,
    loading: false,
    subcate1: '',
    problemList: [],
  })
}

const clearSubcate2 = (state, action) => {
  return updateState(state, {
    subcate2List: []
  })
}

const getSubcate1Start = (state, action) => {
  return updateState(state, {
    loading: true
  })
}

const getSubcate1Success = (state, action) => {
  return updateState(state, {
    subcate1: action.subcate1,
    loading: false
  })
}

const clearSubcate1 = (state, action) => {
  return updateState(state, {
    subcate1: '',
  })
}

const getPurchaseListStart = (state, action) => {
  return updateState(state, {
    loading: true
  })
}

const getPurchaseListSuccess = (state, action) => {
  return updateState(state, {
    purchaseList: action.purchaseList,
    loading: false
  })
}

const getRelationListStart = (state, action) => {
  return updateState(state, {
    loading: true
  })
}

const getRelationListSuccess = (state, action) => {
  return updateState(state, {
    relationList: action.relationList,
    loading: false
  })
}

const clearPurchaseList = (state, action) => {
  return updateState(state, {
    purchaseList: []
  })
}

const savePurchaseForm = (state, action) => {
  return updateState(state, {
    purchaseFields: action.purchaseFields
  })
}

const getProblemListStart = (state, action) => {
  return updateState(state, {
    loading: true
  })
}

const getProblemListSuccess = (state, action) => {
  return updateState(state, {
    problemList: action.problemList,
    loading: false
  })
}

const clearProblemList = (state, action) => {
  return updateState(state, {
    problemList: []
  })
}

const saveProblemForm = (state, action) => {
  return updateState(state, {
    problemFields: action.problemFields
  })
}

const getRequestListStart = (state, action) => {
  return updateState(state, {
    loading: true
  })
}

const getRequestListSuccess = (state, action) => {
  return updateState(state, {
    requestList: action.requestList,
    loading: false
  })
}

const clearRequestList = (state, action) => {
  return updateState(state, {
    requestList: []
  })
}

const saveNewRequest = (state, action) => {
  return updateState(state, {
    request: action.request
  })
}

const saveOwner = (state, action) => {
  return updateState(state, {
    ownerid: action.ownerid,
    ownername: action.ownername
  })
}

const callApiFail = (state, action) => {
  return updateState(state, {
    loading: false
  })
}

const saveForLaterStart = (state, action) => {
  return updateState(state, {
    loading: true
  })
}

const saveForLaterSuccess = (state, action) => {
  return updateState(state, {
    loading: false,
    saveRedirect: true
  })
}

const getTicketListStart = (state, action) => {
  return updateState(state, {
    loading: true
  })
}

const submitCardStart = (state, action) => {
  return updateState(state, {
    loading: true
  })
}

const getTicketListSuccess = (state, action) => {
  return updateState(state, {
    loading: false,
    ticketList: action.ticketList
  })
}

const submitCardSuccess = (state, action) => {
  return updateState(state, {
    loading: false,
    ticketid: action.ticketid,
    saveRedirect: false,
    nextAds: true
  })
}

const getTicketDetailStart = (state, action) => {
  return updateState(state, {
    loading: true
  })
}

const getTicketDetailSuccess = (state, action) => {
  return updateState(state, {
    loading: false,
    accountid: action.accountid,
    accountname: action.accountname,
    ticketid: action.ticketid,
    ticketdate: action.ticketdate,
    subcate1: action.subcate1,
    subcate2: action.subcate2,
    purchaseFields: {
      sufferer: action.sufferer,
      productname: action.productname,
      purchasedate: action.purchasedate,
      payment : action.payment,
      productprice : action.productprice,
      purchase : action.purchase,
      website : action.website,
      store : action.store,
      delegate: action.delegate === '1' ? true:false,
      relation: action.relation,
    },
    problemFields: {
      problem: action.problem,
      damage: action.damage,
      desc: action.desc
    },
    request: action.request,
    ownerid : action.ownerid,
    ownername: getOwnerNameByID(action.ownerid),
    solution: action.solution,
    isUpdate: true
  })
}

const saveImageFile = (state, action) => {
  return updateState(state, {
    imageFile: action.file
  })
}

const clearSummaryComplaint = (state, action) => {
  return updateState(state, {
    accountid: null,
    accountname: '',
    ticketid: '',
    ticketdate: getISODateString(new Date()),
    subcate1: '',
    subcate2: '',
    results : [],
    subcate2List: [],
    purchaseList: [],
    purchaseFields: {
      sufferer: '',
      productname: '',
      purchasedate: getISODateString(new Date()),
      payment : '',
      productprice : '',
      purchase : '',
      website : '',
      store : '',
    },
    problemFields: {
      problem: '',
      damage: '',
      desc: ''
    },
    problemList: [],
    requestList: [],
    request: '',
    ownerid : constant.DEFAULT_OWNER_ID,
    ownername: '',
    solution: '',
    imageFile: [
      {
        file: null,
        id: 'file_input_1'
      },
      {
        file: null,
        id: 'file_input_2'
      },
      {
        file: null,
        id: 'file_input_3'
      }
    ],
    ticketList: null,
    loading: false,
    searchLoading: false,
    enable : false,
    saveRedirect: false,
    nextAds: false,
    isUpdate: false
  })
}

const setError = (state, action) => {
  return updateState(state, {
    error: action.error,
    loading: false
  })
}

const clearError = (state, action) => {
  return updateState(state, {
    error: null
  })
}



const reducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.SEARCH_START:
      return searchStart(state, action)

    case actionTypes.SEARCH_SUCCESS:
      return searchSuccess(state, action)

    case actionTypes.SEARCH_STOP:
      return searchStop(state, action)

    case actionTypes.SEARCH_SELECTED:
      return searchSelected(state, action)

    case actionTypes.SAVE_SUBCATE2:
      return saveSubcate2(state, action)

    case actionTypes.GET_SUBCATE2_START:
      return getSubcate2Start(state, action)

    case actionTypes.GET_SUBCATE2_SUCCESS:
      return getSubcate2Success(state, action)

    case actionTypes.CLEAR_SUBCATE2:
      return clearSubcate2(state, action)

    case actionTypes.GET_SUBCATE1_START:
      return getSubcate1Start(state, action)

    case actionTypes.GET_SUBCATE1_SUCCESS:
      return getSubcate1Success(state, action)

    case actionTypes.CLEAR_SUBCATE1:
      return clearSubcate1(state, action)

    case actionTypes.GET_PURCHASE_LIST_START:
      return getPurchaseListStart(state, action)

    case actionTypes.GET_PURCHASE_LIST_SUCCESS:
      return getPurchaseListSuccess(state, action)

    case actionTypes.CLEAR_PURCHASE_LIST:
      return clearPurchaseList(state, action)

    case actionTypes.GET_RELATION_LIST_START:
      return getRelationListStart(state, action)

    case actionTypes.GET_RELATION_LIST_SUCCESS:
      return getRelationListSuccess(state, action)

    case actionTypes.SAVE_PURCHASE_FORM:
      return savePurchaseForm(state, action)

    case actionTypes.GET_PROBLEM_LIST_START:
      return getProblemListStart(state, action)

    case actionTypes.GET_PROBLEM_LIST_SUCCESS:
      return getProblemListSuccess(state, action)

    case actionTypes.CLEAR_PROBLEM_LIST:
      return clearProblemList(state, action)

    case actionTypes.SAVE_PROBLEM_FORM:
      return saveProblemForm(state, action)

    case actionTypes.GET_REQUEST_LIST_START:
      return getRequestListStart(state, action)

    case actionTypes.GET_REQUEST_LIST_SUCCESS:
      return getRequestListSuccess(state, action)

    case actionTypes.CLEAR_REQUEST_LIST:
      return clearRequestList(state, action)

    case actionTypes.SAVE_NEW_REQUEST:
      return saveNewRequest(state, action)

    case actionTypes.SAVE_OWNER:
      return saveOwner(state, action)

    case actionTypes.SAVE_FOR_LATER_START:
      return saveForLaterStart(state, action)

    case actionTypes.SAVE_FOR_LATER_SUCCESS:
      return saveForLaterSuccess(state, action)

    case actionTypes.CALL_API_FAIL:
      return callApiFail(state, action)

    case actionTypes.GET_TICKET_LIST_START:
      return getTicketListStart(state, action)

    case actionTypes.GET_TICKET_LIST_SUCCESS:
      return getTicketListSuccess(state, action)

    case actionTypes.SUBMIT_CARD_START:
      return submitCardStart(state, action)

    case actionTypes.SUBMIT_CARD_SUCCESS:
      return submitCardSuccess(state, action)

    case actionTypes.GET_TICKET_DETAIL_START:
      return getTicketDetailStart(state, action)

    case actionTypes.GET_TICKET_DETAIL_SUCCESS:
      return getTicketDetailSuccess(state, action)

    case actionTypes.CLEAR_SUMMARY_COMPLAINT:
      return clearSummaryComplaint(state, action)

    case actionTypes.SET_ERROR:
      return setError(state, action)

    case actionTypes.CLEAR_ERROR:
      return clearError(state, action)

    default:
      return state
  }
}

export default reducer
