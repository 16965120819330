import React from 'react'

const Content = (props) => {
  return(
    <div className="text-center text-color font-all">
      <p>กรุณาระบุคู่กรณี</p>
      <p>บริษัท/ห้างร้าน/องค์กร/หน่วยงาน</p>
      <p>ที่ท่านต้องการร้องเรียน</p>
    </div>
  )
}

export default Content
