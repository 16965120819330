import React from 'react'

const user = (props) => {
  return (
    <form className="form-inline mt-2 mt-md-0 top-bar-right">
      <button 
        className="btn btn-light my-2 my-sm-0 font-text" 
        onClick = {props.onLogout}> 
        ออกจากระบบ
      </button>
    </form>
  )
}

export default user

