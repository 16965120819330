import * as actionTypes from './actionTypes'
import axios from '../../axios-vtiger.js'
import * as constant from '../constant.js'
import {sessionMiddleware} from '../actions/session.js'

export const search = (text, sessionID) => {

  return dispatch => {
    dispatch(searchStart())
    callSearchApi(dispatch, text, sessionID, 0)
  }
}

export const callSearchApi = (dispatch, text, sessionID, count) => {
  const URI = '?function=GetAccountListByAccountName&accountname='+text+'&sessionid='+sessionID
  axios.get(URI)
    .then(response => {
      if (response.data.code) {
        if (count < 1) {
          sessionMiddleware(dispatch, count, (session, count) => callSearchApi(dispatch, text, session, count))
        } else {
          dispatch(setError('search error : ' + JSON.stringify(response.data)))
        }
        return
      }

      const results = getResults(response.data, text)
      dispatch(searchSuccess(results))
    })
}

const getResults = (resarray, text) => {

  let acclist = []
  if(Array.isArray(resarray)) {
    acclist = resarray.map(res => {
      return {
        title: res.accountname,
        id: res.id
      }
    })
  }

  if(acclist.length > 0){
    return acclist
  }else{
    return [{title : constant.NO_RESULT_FOUND}]
  }    
}

const searchStart = () => {
  return {
    type: actionTypes.SEARCH_START
  }
}

const searchSuccess = (results) => {
  return {
    type: actionTypes.SEARCH_SUCCESS,
    results: results
  }
}

export const searchStop = () => {
  return {
    type: actionTypes.SEARCH_STOP
  }
}

export const searchSelected = (result) => {
  return {
    type: actionTypes.SEARCH_SELECTED,
    accountname: result.title,
    accountid: result.id
  }
}
export const saveSubcate2 = (subcate2) => {
  return {
    type: actionTypes.SAVE_SUBCATE2,
    subcate2: subcate2
  }
}

export const clearSubcate2 = () => {
  return {
    type: actionTypes.CLEAR_SUBCATE2
  }
}

export const getSubcate2 = (sessionID) => {
  return dispatch => {
    dispatch(getSubcate2Start())
    callSubcate2(dispatch, sessionID, 0)
  }
}

const callSubcate2 = (dispatch, sessionID, count) => {
  const getURL = '?function=GetPicklist&fieldname='+window.env.cfCode.subcate2+'&sessionid='+sessionID
  axios.get(getURL)
    .then(response => {
      if (response && response.data && response.status === 200) {
        if (response.data.code) {
          if (count < 1) {
            sessionMiddleware(dispatch, count, (session, count) => callSubcate2(dispatch, session, count))
          } else {
            dispatch(setError('get catgory error : ' + JSON.stringify(response.data)))
          }
          return
        }
        dispatch(getSubcate2Success(response.data))
      }else {
        dispatch(setError('get catgory error : ' + JSON.stringify(response.data)))
      }
    })
}

const getSubcate2Start = () => {
  return {
    type: actionTypes.GET_SUBCATE2_START
  }
}

const getSubcate2Success = (subcate2List) => {
  return {
    type: actionTypes.GET_SUBCATE2_SUCCESS,
    subcate2List: subcate2List
  }
}

export const getSubcate1  = (subcate2) => {
  const URI = '?function=GetSubCategoryByMainCategory&category='+subcate2+
    '&sourcefield='+window.env.cfCode.subcate2+
    '&targetfield='+window.env.cfCode.subcate1

  return dispatch => {
    dispatch(getSubcate1Start())
    axios.get(URI)
      .then(response => {
        if (response && response.status === 200) {
          if (!Array.isArray(response.data)) {
            dispatch(setError('getSubcate1 error : ' + JSON.stringify(response.data)))
          } else {
            dispatch(getSubcate1Success(response.data[0]))
          }
        }else {
          dispatch(setError('get subcate1 error : ' + JSON.stringify(response.data)))
        }
      })
  }
}

export const getSubcate1Start = () => {
  return {
    type: actionTypes.GET_SUBCATE1_START
  }
}

export const getSubcate1Success = (subcate1) => {
  return {
    type: actionTypes.GET_SUBCATE1_SUCCESS,
    subcate1: subcate1
  }
}

export const clearSubcate1 = () => {
  return {
    type: actionTypes.CLEAR_SUBCATE1
  }
}

export const getPurchaseList = (sessionID) => {
  return dispatch => {
    dispatch(getPurchaseListStart())
    callPurchaseListApi(dispatch, sessionID, 0)
  }
}

export const callPurchaseListApi = (dispatch, sessionID, count) => {
  const URI = '?function=GetPicklist&fieldname='+window.env.cfCode.purchaseList+
    '&sessionid='+sessionID
  axios.get(URI)
    .then(response => {
      if(response && response.data && response.status === 200) {
        if (response.data.code) {
          if (count < 1) {
            sessionMiddleware(dispatch, count, (session, count) => callPurchaseListApi(dispatch, session, count))
          } else {
            dispatch(setError('getPurchaseList error : ' + JSON.stringify(response.data)))
          }
          return
        }

        if (!Array.isArray(response.data)) {
          dispatch(setError('getPurchaseList error : ' + JSON.stringify(response.data)))
        } else {
          dispatch(getPurchaseListSuccess(response.data))
        }
      }else {
        dispatch(setError('getPurchaseList error : ' + JSON.stringify(response.data)))
      }
    })
}

export const getPurchaseListStart = () => {
  return {
    type: actionTypes.GET_PURCHASE_LIST_START
  }
}

export const getPurchaseListSuccess = (purchaseList) => {

  return {
    type: actionTypes.GET_PURCHASE_LIST_SUCCESS,
    purchaseList: purchaseList
  }
}


export const getRelationList = (sessionID) => {
  return dispatch => {
    dispatch(getRelationListStart())
    callRelationListApi(dispatch, sessionID, 0)
  }
}

export const callRelationListApi = (dispatch, sessionID, count) => {
  const URI = '?function=GetPicklist&fieldname='+window.env.cfCode.relationList+
    '&sessionid='+sessionID
  axios.get(URI)
    .then(response => {
      if(response && response.data && response.status === 200) {
        if (response.data.code) {
          if (count < 1) {
            sessionMiddleware(dispatch, count, (session, count) => callRelationListApi(dispatch, session, count))
          } else {
            dispatch(setError('getRelationList error : ' + JSON.stringify(response.data)))
          }
          return
        }

        if (!Array.isArray(response.data)) {
          dispatch(setError('getRelationList error : ' + JSON.stringify(response.data)))
        } else {
          dispatch(getRelationListSuccess(response.data))
        }
      }else {
        dispatch(setError('getRelationList error : ' + JSON.stringify(response.data)))
      }
    })
}

export const getRelationListStart = () => {
  return {
    type: actionTypes.GET_RELATION_LIST_START
  }
}

export const getRelationListSuccess = (relationList) => {
  return {
    type: actionTypes.GET_RELATION_LIST_SUCCESS,
    relationList: relationList,
  }
}

export const clearPurchaseList = () => {
  return {
    type: actionTypes.CLEAR_PURCHASE_LIST
  }
}

export const savePurchaseForm = (fields) => {
  return {
    type: actionTypes.SAVE_PURCHASE_FORM,
    purchaseFields: fields
  }
}

export const getProblemList = (subcate2) => {

  const URI = '?function=GetSubCategoryByMainCategory&category='+subcate2+
    '&sourcefield='+window.env.cfCode.subcate2+
    '&targetfield='+window.env.cfCode.problemList
  return dispatch => {
    dispatch(getProblemListStart())
    axios.get(URI)
      .then(response => {
        if(response && response.status === 200) {
          if (!Array.isArray(response.data)) {
            dispatch(setError('getProblemList error : ' + JSON.stringify(response.data)))
          } else {
            dispatch(getProblemListSuccess(response.data))
          }
        }else {
          dispatch(setError('getProblemList error : ' + JSON.stringify(response.data)))
        }
      })
  }
}

const getProblemListStart = () => {
  return {
    type: actionTypes.GET_PROBLEM_LIST_START
  }
}

const getProblemListSuccess = (problemList) => {
  return {
    type: actionTypes.GET_PROBLEM_LIST_SUCCESS,
    problemList: problemList
  }
}

export const clearProblemList = () => {
  return {
    type: actionTypes.CLEAR_PROBLEM_LIST
  }
}

export const saveProblemForm = (fields) => {
  return {
    type: actionTypes.SAVE_PROBLEM_FORM,
    problemFields: fields
  }
}

export const clearRequestList = () => {
  return {
    type: actionTypes.CLEAR_REQUEST_LIST
  }
}

export const getRequestList = (sessionID) => {
  return dispatch => {
    dispatch(getRequestListStart())
    callRequestList(dispatch, sessionID, 0)
  }
}

const callRequestList = (dispatch, sessionID, count) => {
  const URI = '?function=GetPicklist&fieldname='+window.env.cfCode.requestList+
    '&sessionid=' + sessionID
  axios.get(URI)
    .then(response => {
      if(response && response.data && response.status === 200) {

        if (response.data.code) {
          if (count < 1) {
            sessionMiddleware(dispatch, count, (session, count) => callRequestList(dispatch, session, count))
          } else {
            dispatch(setError('getRequestList error : ' + JSON.stringify(response.data)))
          }
          return
        }

        if (!Array.isArray(response.data)) {
          dispatch(setError('getRequestList error : ' + JSON.stringify(response.data)))
        } else {
          dispatch(getRequestListSuccess(response.data))
        }

      }else {
        dispatch(setError('getRequestList error : ' + JSON.stringify(response.data)))
      }
    })


}

const getRequestListStart = () => {
  return {
    type: actionTypes.GET_REQUEST_LIST_START
  }
}

const getRequestListSuccess = (requestList) => {
  return {
    type: actionTypes.GET_REQUEST_LIST_SUCCESS,
    requestList: requestList
  }
}

export const saveNewRequest = (request) => {
  return {
    type: actionTypes.SAVE_NEW_REQUEST,
    request: request
  }
}

export const saveOwner = (id, name) => {
  return {
    type: actionTypes.SAVE_OWNER,
    ownerid: id,
    ownername: name
  }
}

export const callApiFail = () => {
  return {
    type: actionTypes.CALL_API_FAIL
  }
}

export const getTicketList = (contactid, sessionID) => {
  return dispatch => {
    dispatch(getTicketListStart())
    callGetTicketListApi(dispatch, contactid, sessionID, 0)
  }
}


const callGetTicketListApi = (dispatch, contactid, sessionID, count) => {
  const URI = '?function=GetTicketListByContactID&contactid=' + contactid + '&sessionid=' + sessionID
  axios.get(URI)
    .then(response => {
      if(response && response.status === 200) {

        if (response.data === null) {
          dispatch(getTicketListSuccess([]))
          return
        }

        if (response.data.code) {
          if (count < 1) {
            sessionMiddleware(dispatch, count, (session, count) => callGetTicketListApi(dispatch, contactid, session, count))
          } else {
            dispatch(setError('getTicketList error : ' + JSON.stringify(response.data)))
          }
          return
        }

        if (!Array.isArray(response.data)) {
          dispatch(setError('getTicketList error : ' + JSON.stringify(response.data)))
          return
        }
        dispatch(getTicketListSuccess(response.data))

      }else {
        dispatch(setError('getTicketList error : ' + JSON.stringify(response.data)))
      }
    })

}

const getTicketListStart = () => {
  return {
    type: actionTypes.GET_TICKET_LIST_START
  }
}

const getTicketListSuccess = (ticketList) => {
  return {
    type: actionTypes.GET_TICKET_LIST_SUCCESS,
    ticketList: ticketList
  }
}

export const getTicketDetail = (ticketID, sessionID) => {
  return dispatch => {
    dispatch(getTicketDetailStart())
    callTicketDetail(dispatch, ticketID, sessionID, 0)
  }
}

const callTicketDetail = (dispatch, ticketID, sessionID, count) => {
  const URI = '?function=GetTicketDetailByTicketID&ticketid=' + ticketID + '&sessionid='+ sessionID
  axios.get(URI)
    .then(response => {
      if(response && response.data && response.status === 200) {

        if (response.data.code) {
          if (count < 1) {
            sessionMiddleware(dispatch, count, (session, count) => callTicketDetail(dispatch, ticketID, session, count))
          } else {
            dispatch(setError('getTicketDetail error : ' + JSON.stringify(response.data)))
          }
          return
        }

        dispatch(getTicketDetailSuccess(response.data))
      }else {
        dispatch(setError('getTicketDetail error : ' + JSON.stringify(response.data)))
      }
    })

}

const getTicketDetailStart = () => {
  return {
    type: actionTypes.GET_TICKET_DETAIL_START
  }
}

const getTicketDetailSuccess = (ticketDetail) => {
  return {
    type: actionTypes.GET_TICKET_DETAIL_SUCCESS,
    ...ticketDetail
  }
}

export const clearSummaryComplaint = () => {
  return {
    type: actionTypes.CLEAR_SUMMARY_COMPLAINT
  }
}

export const saveImageFile = (file) => {
  return {
    type: actionTypes.SAVE_UPLOAD_IMAGE,
    file: file,
  }
}

const uploadImage = (ticketid, ticketno, index, sessionID, file) => {       

  const form = newFormData(ticketid, ticketno, index, sessionID, file)
  axios.post('/', form, {'Content-Type': 'multipart/form-data' }) 
    .then(response => {                                    
      if(response.data.result && response.data.result != 'success') {
        axios.post('/', form, {'Content-Type': 'multipart/form-data' }) 
          .then(response => {                                    
            if(response.data.result && response.data.result != 'success') {
              return 'อัปโหลดรูปภาพผิดภาพ'
            }
          })           
      }
    })                                                
  return null
}     

export const uploadImages = (ticketid, ticketno, sessionID, imageFile) => {
  return dispatch => {

    dispatch(uploadImagesStart())
    imageFile.map((file, index) => {
      if (file.file !== null) {
        const err = uploadImage(ticketid, ticketno, index, sessionID, file.file)
        if (err !== null) {
          dispatch(setError(err))
        }
      }
    })
    dispatch(uploadImagesSuccess())
  }
}

const uploadImagesStart = () => {
  return {
    type: actionTypes.UPLOAD_IMAGE_START
  }
}

const uploadImagesSuccess = () => {
  return {
    type: actionTypes.UPLOAD_IMAGE_SUCCESS
  }
}

const newFormData = (ticketid, ticketno, index, sessionID, file) => {
  const formData = new FormData()
  const id = index + 1
  formData.append('function', 'UploadImage')
  formData.append('ticketid', ticketid)
  formData.append('title', ticketno + '-' + id)
  formData.append('sessionid', sessionID)
  formData.append('imagename', file)
  return formData
}

export const submitCard = (fields, sessionID) => {
  return dispatch => {
    dispatch(submitCardStart())
    callSubmit(dispatch, fields, sessionID, 0)
  }
}

const callSubmit = (dispatch, fields, sessionID, count) => {
  const URI = '?function=UpdateNewTicket&ticketid='+fields.ticketid+'&status=In Progress'+'&contactid='+fields.contactid+'&title='+fields.title+
  '&accountid='+fields.accountid+'&accountname='+fields.accountname+'&category=ร้องเรียน'+
  '&subcate1='+fields.subcate1+'&subcate2='+fields.subcate2+'&subcate3='+
  '&channel='+window.env.channel+'&productname='+fields.productname+'&productprice='+fields.productprice+
  '&purchasedate='+fields.purchasedate+'&payment='+fields.payment+'&purchase='+fields.purchase
  +'&website='+fields.website+'&store='+fields.store+'&problem='+fields.problem+'&damage='+fields.damage+'&desc='+fields.desc+
  '&request='+fields.request+'&ownerid='+fields.ownerid+'&sufferer='+fields.sufferer+
  '&delegate='+fields.delegate+'&relation='+fields.relation+'&sessionid='+sessionID

  axios.get(URI)
    .then(response => {
      if(response && response.data && response.status === 200) {

        if (response.data.code) {
          if (count < 1) {
            sessionMiddleware(dispatch, count, (session, count) => callSubmit(dispatch, fields, session, count))
          } else {
            dispatch(setError('callSubmit error : ' + JSON.stringify(response.data)))
          }
          return
        }

        dispatch(uploadImages(response.data.ticketid, response.data.ticketno, fields.sessionID, fields.imageFile))
        dispatch(submitCardSuccess(response.data, fields.imageFile))
      }else {
        dispatch(setError('submitCard error : ' + JSON.stringify(response.data)))
      }
    })


}

const submitCardStart = () => {
  return {
    type: actionTypes.SUBMIT_CARD_START
  }
}

const submitCardSuccess = (data) => {
  return {
    type: actionTypes.SUBMIT_CARD_SUCCESS,
    ticketid: data.ticketid,
  }
}

export const saveForLater = (fields, sessionID) => {


  return dispatch => {
    dispatch(saveForLaterStart())
    callSaveForLater(dispatch, fields, sessionID, 0)
  }
}

const callSaveForLater = (dispatch, fields, sessionID, count) => {
  const URI = '?function=UpdateNewTicket&ticketid='+fields.ticketid+'&status=Open'+'&contactid='+fields.contactid+'&title='+fields.title+
  '&accountid='+fields.accountid+'&accountname='+fields.accountname+'&category=ร้องเรียน'+
  '&subcate1='+fields.subcate1+'&subcate2='+fields.subcate2+'&subcate3='+
  '&channel='+window.env.channel+'&productname='+fields.productname+'&productprice='+fields.productprice+
  '&purchasedate='+fields.purchasedate+'&payment='+fields.payment+'&purchase='+fields.purchase
  +'&website='+fields.website+'&store='+fields.store+'&problem='+fields.problem+'&damage='+fields.damage+'&desc='+fields.desc+
  '&request='+fields.request+'&ownerid='+fields.ownerid+'&sufferer='+fields.sufferer+
  '&delegate='+fields.delegate+'&relation='+fields.relation+'&sessionid='+sessionID

  axios.get(URI)
    .then(response => {
      if(response && response.data && response.status === 200) {
        if (response.data.code) {
          if (count < 1) {
            sessionMiddleware(dispatch, count, (session, count) => callSaveForLater(dispatch, fields, session, count))
          } else {
            dispatch(setError('callSaveForLater error : ' + JSON.stringify(response.data)))
          }
          return
        }

        dispatch(uploadImages(response.data.ticketid, response.data.ticketno, sessionID, fields.imageFile))
        dispatch(saveForLaterSuccess(response.data))
      }else {
        dispatch(setError('saveForLater error : ' + JSON.stringify(response.data)))
      }
    })


}

export const saveForLaterStart = () => {
  return {
    type: actionTypes.SAVE_FOR_LATER_START
  }
}

export const saveForLaterSuccess = (data) => {
  return {
    type: actionTypes.SAVE_FOR_LATER_SUCCESS
  }
}

export const setError = (message) => {
  return {
    type: actionTypes.SET_ERROR,
    error: {
      message: message
    }
  }
}

export const clearError = (message) => {
  return {
    type: actionTypes.CLEAR_ERROR,
  }
}
