import React from 'react'
import './ToggleSwitchForm.css'
import {Switch} from 'antd'

const toggleSwitchForm = (props) => {

  return (
    <div className="form-group row">
      <label 
        className="col-12 col-sm-12 col-form-label">
        {props.label}
      </label>
      <div className="col-12 col-sm-12">
        <Switch
          id={props.id}
          checked={props.checked}
          onClick={props.onClick}
        />
      </div>
    </div>
  )
}

export default toggleSwitchForm
