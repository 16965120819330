import React from 'react'
import { ListGroup, ListGroupItem } from 'react-bootstrap'

const formResponse = (props) => {

  return props.requestList.map((request, index) => {
    return (
      <div key={index}>
        <ListGroup>
          <ListGroupItem 
            className = "text-left" 
            id = {index} 
            value = {request} 
            style = {{ backgroundColor: props.background(request)}} 
            onClick = {props.onClick}>
            {request} 
          </ListGroupItem>
        </ListGroup>
      </div>
    )
  })
}
export default formResponse
