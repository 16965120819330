import React from 'react'
//import { Link} from 'react-router-dom'

const BtnNewAcc = (props) => {
  return(
    <div className="text-center">
      <p className="font-gray">หากไม่พบคู่กรณีในระบบสามารถเพิ่มคู่กรณีใหม่ได้</p>
      <button type="button" className="btn btnback font-all" onClick={props.account}>เพิ่มคู่กรณี</button>
    </div>
  )
}

export default BtnNewAcc

/*
 <Link to={props.addAccount}><button type="button" className="btn btnback font-all">เพิ่มคู่กรณี</button></Link>

 */
