import * as actionTypes from './actionTypes'
import axios from '../../axios-vtiger.js'
import {sessionMiddleware} from '../actions/session.js'

export const saveAds = (adsFields) => {
  return {
    type: actionTypes.SAVE_ADS,
    adsFields: adsFields
  }
}

export const callApiFail = () => {
  return {
    type: actionTypes.CALL_API_FAIL
  }
}

export const submitAds = (fields, sessionID) => {

  return dispatch => {
    dispatch(submitAdsStart())
    callSubmitAds(dispatch, fields, sessionID, 0)
  }
}

const callSubmitAds = (dispatch, fields, sessionID, count) => {
  const URI = '?function=UpdateNewTicket&ticketid='+fields.ticketid+'&status=In Progress'+'&contactid='+fields.contactid+'&title='+fields.title+
  '&accountid='+fields.accountid+'&accountname='+fields.accountname+'&category=ร้องเรียน'+
  '&subcate1='+fields.subcate1+'&subcate2='+fields.subcate2+'&subcate3='+
  '&channel='+window.env.channel+'&productname='+fields.productname+'&productprice='+fields.productprice+
  '&purchasedate='+fields.purchasedate+'&payment='+fields.payment+'&purchase='+fields.purchase
  +'&website='+fields.website+'&store='+fields.store+'&problem='+fields.problem+'&damage='+fields.damage+'&desc='+fields.desc+
    '&request='+fields.request+'&ownerid='+fields.ownerid+'&sufferer='+fields.sufferer+
    '&public='+fields.public+'&donate='+fields.donate+'&member='+fields.member+
    '&delegate='+fields.delegate+'&relation='+fields.relation+'&sessionid='+sessionID

  axios.get(URI)
    .then(response => {
      if (response.data.code) {
        if (count < 1) {
          sessionMiddleware(dispatch, count, (session, count) => callSubmitAds(dispatch, fields, session, count))
        } else {
          dispatch(callApiFail())
        }
        return
      }
      if(response && response.status === 200) {
        dispatch(submitAdsSuccess(response.data))
      }else {
        dispatch(callApiFail())
      }
    })
}
    
export const submitAdsStart = () => {
  return {
    type: actionTypes.SUBMIT_ADS_START
  }
}
    
export const submitAdsSuccess = (ticketid) => {
  return {
    type: actionTypes.SUBMIT_ADS_SUCCESS,
    ticketid: ticketid,
  }
}
    
export const clearRedirect = () => {
  return {
    type: actionTypes.CLEAR_REDIRECT
  }
}
