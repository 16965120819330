import React from 'react'

const AccountForm = (props) => {
  return(
    <div className="container">
      <div className="form-group row">
        <label htmlFor="productname" className="col-12 col-sm-12 col-form-label">ชื่อคู่กรณี</label>
        <div className="col-12 col-sm-12">
          <input type="text" className="form-control" id="accountname" placeholder="กรุณาระบุชื่อบริษัทฯ/ห้างร้าน/องค์กร/หน่วยงาน ที่ไม่พบคู่กรณีในระบบ" value={props.accountName} onChange={props.handleChange} required/>
        </div>
      </div>
    </div>
  )
}
  
export default AccountForm