import React from 'react'
import LoginFields from './LoginFields/LoginFields'

const loginForm = (props) => {

  
  let title = <h2 className="jumbotron-heading font-text">{props.title}</h2>
  if (props.isPopup) {
    title = <h4 className="jumbotron-heading font-text">{props.title}</h4>
  }

  return (
    <div >
      <br/>
      {title}
      {props.loginError && <p className="error-message">{props.loginError}</p>}
      <LoginFields
        {...props}
      />

      <div className="button-line">
        <button 
          className="btn btn-primary font-text" 
          onClick = {props.manualLogin}>
            เข้าสู่ระบบ
        </button>
      </div>     
    </div>      
  )
}

export default loginForm

/*
          <button type="submit" className="btn btn-primary">เข้าสู่ระบบ</button>
          */
