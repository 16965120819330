import React from 'react'
import TextField from 'material-ui/TextField'
import './ForgetPasswordPopup.css'


const forgetPasswordPopup = (props) => {


  let successMessage = null
  if (props.showSuccess) {
    successMessage = (
      <p className="jumbotron-heading font-text ForgetPasswordPopup-success-text">ระบบได้ส่งรหัสผ่านไปให้ทางอีเมลดังกล่าวแล้ว</p>
    )
  }

  let errorMessage = null
  if (props.showError) {
    errorMessage = (
      <p className="jumbotron-heading font-text ForgetPasswordPopup-error-text">ไม่พบอีเมลนี้ในระบบลงทะเบียน</p>
    )
  }

  return (
    <div className='ForgetPasswordPopup'>
      <h4 className="jumbotron-heading font-text ForgetPasswordPopup-maintext">กรอกอีเมลที่คุณลงทะเบียนไว้และระบบจะส่งรหัสผ่านไปให้ทางอีเมล</h4>
      <TextField
        floatingLabelText="อีเมล"
        name="email"
        value={props.email}
        onChange={props.onChange}
      />
      {successMessage}
      {errorMessage}
      <div>
        <button 
          className="btn btn-primary ForgetPasswordPopup-button" 
          onClick={props.onForgetPasswordButtonClicked}
        >ขอรหัสผ่าน</button>
      </div>
    </div>
  )
}

export default forgetPasswordPopup
