import React, {Component} from 'react'
import ToolBar from './../../components/Navigation/ToolBar/ToolBar'
import {connect} from 'react-redux'
import Modal from '../../components/UI/Modal/Modal.js'
import * as actions from '../../store/actions/index'

import './Layout.css'

class Layout extends Component {

  onLogoutHandler = () => {
    this.props.clearComplaint()
    this.props.clearRedirect()
    this.props.onLogout()
    window.location.replace('/')
  }

  render() {

    return (
      <div className = "Layout">
        <Modal error show = {this.props.error} modalClosed = {this.props.clearError}>
          {this.props.error? this.props.error.message : null}
        </Modal>
        <ToolBar 
          username = {this.props.username}
          onLogout = {this.onLogoutHandler}
        />
        {this.props.children}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    username: state.user.username,
    error: state.complaint.error,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearError: () => dispatch(actions.clearError()),
    clearComplaint: () => dispatch(actions.clearSummaryComplaint()),
    clearRedirect: () => dispatch(actions.clearRedirect()),
    onLogout: () => dispatch(actions.logout())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)

