import React, { Component } from 'react'
import UploadImageBox from './UploadImageBox/UploadImageBox.js'

const uploadImageBoxes = (props) => {

  return props.fileList.map(file => {

    let image = null
    let fileType = null
    if (file.file) {
      fileType = file.file.type
      image = URL.createObjectURL(file.file)
    }

    return (
      <UploadImageBox
        key = {file.id}
        id = {file.id}
        file = {image}
        fileType = {fileType}
        onChange = {props.onChange}
        onRemove = {props.onRemove}
      />
    )
  })
}

export default uploadImageBoxes

