import React from 'react'
import SelectForm from '../../../components/UI/Forms/SelectForm/SelectForm.js'
import PriceForm from '../../../components/UI/Forms/PriceForm/PriceForm.js'
import * as constant from '../../../utils/constant.js'

const problem = (props) => {

  let showError = 'none'
  if (props.errors.desc) {
    showError = ''
  }

  return (

    <div className="container">

      <SelectForm
        id = "problem"
        label = 'กรุณาระบุปัญหาที่เกิดจากการใช้สินค้าหรือบริการ'
        value = {props.fields.problem}
        options = {props.problemList}
        onChange = {props.onChange}
      />

      <PriceForm
        id = "damage"
        label = "มูลค่าเรียกร้องค่าเสียหาย"
        value = {props.fields.damage}
        onChange = {props.onChange}
      />

      <div className="form-group row">
        <label className="col-12 col-sm-12 col-form-label">รายละเอียดเกี่ยวกับเหตุการณ์ที่เกิดขึ้น</label>
        <div className="col-12 col-sm-12">
          <textarea 
            className = "form-control" 
            placeholder = {constant.PROBLEM_LIMIT_DESC_WARN}
            aria-label = "With textarea" 
            id="desc" 
            value={props.fields.desc} 
            onChange={props.onChange}
          >
          </textarea>
        </div>
      </div>
      <div className="col-12 col-sm-12">
        <p id='err_productname' className="font-red" style={{display: showError}}>{constant.PROBLEM_LIMIT_DESC_WARN}</p>                    
      </div>       

    </div>
    

  )
}

export default problem 

