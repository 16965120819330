import * as actionTypes from '../actions/actionTypes'
import {updateState} from '../utils'

const initialState = {
  username: null,
  contactId: null,
  phone: null,
  email: null,
  loading: false,
  loginError: null,
  forgetPasswordError: null,
  forgetPasswordSuccess: false,
}

const forgetPasswordStart = (state, action) => {
  return updateState(state, {loading: true})
}

const forgetPasswordSuccess = (state, action) => {
  return updateState(state, {
    loading: false,
    forgetPasswordError: null,
    forgetPasswordSuccess: true,
  })
}

const forgetPasswordFail = (state, action) => {
  return updateState(state, {
    loading: false,
    forgetPasswordError: action.forgetPasswordError,
    forgetPasswordSuccess: false,
  })
}

const forgetPasswordClear = (state, action) => {
  return updateState(state, {
    forgetPasswordError: null,
    forgetPasswordSuccess: false,
  })
}

const loginStart = (state, action) => {
  return updateState(state, {loading: true})
}

const loginSuccess = (state, action) => {
  return updateState(state, {
    loading: false,
    loginError: null,
    username: action.username,
    contactId: action.contactId,
    phone: action.phone,
    email: action.email,
  })
}

const loginFail = (state, action) => {
  return updateState(state, {
    loading: false,
    loginError: action.loginError
  })
}

const logout = (state, action) => {
  return updateState(state, {
    username: null,
    contactId: null,
    phone: null,
    email: null,
  })
}

const loginFacebookSuccess = (state, action) => {
  return updateState(state, {
    loading: false,
    loginError: null,
    username: action.username,
    contactId: action.contactId,
    phone: action.phone,
    email: action.email,
  })
}

const loginFacebookStart = (state, action) => {
  return updateState(state, {
    loading: true
  })
}

const loginFacebookNull = (state, action) => {
  return updateState(state, {
    loading: false
  })
}

const loginFacebookRegisterSuccess = (state, action) => {
  return updateState(state, {
    loading: false,
    username: action.username,
    contactId: action.contactId,
    phone: action.phone,
    email: action.email,
  })
}

const updateContactStart = (state, action) => {
  return updateState(state, {
    loading: true
  })
}

const updateContactFail = (state, action) => {
  return updateState(state, {
    loading: false,
    loginError: action.error,
  })
}

const updateContactSuccess = (state, action) => {
  return updateState(state, {
    loading: false,
    phone: action.phone,
    email: action.email,
    loginError: null,
  })
}

const reducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.LOGIN_START: 
      return loginStart(state, action)
    case actionTypes.LOGIN_SUCCESS:
      return loginSuccess(state, action)
    case actionTypes.LOGIN_FAIL:
      return loginFail(state, action)
    case actionTypes.LOGOUT:
      return logout(state, action)
    case actionTypes.LOGIN_FACEBOOK_START:
      return loginFacebookStart(state, action)
    case actionTypes.LOGIN_FACEBOOK_SUCCESS:
      return loginFacebookSuccess(state, action)
    case actionTypes.LOGIN_FACEBOOK_NULL:
      return loginFacebookNull(state, action)
    case actionTypes.LOGIN_FACEBOOK_REGISTER_SUCCESS:
      return loginFacebookRegisterSuccess(state, action)
    case actionTypes.UPDATE_CONTACT_START:
      return updateContactStart(state, action)
    case actionTypes.UPDATE_CONTACT_FAIL:
      return updateContactFail(state, action)
    case actionTypes.UPDATE_CONTACT_SUCCESS:
      return updateContactSuccess(state, action)
    case actionTypes.FORGET_PASSWORD_START:
      return forgetPasswordStart(state, action)
    case actionTypes.FORGET_PASSWORD_SUCCESS:
      return forgetPasswordSuccess(state, action)
    case actionTypes.FORGET_PASSWORD_FAIL:
      return forgetPasswordFail(state, action)
    case actionTypes.FORGET_PASSWORD_CLEAR:
      return forgetPasswordClear(state, action)

    default:
      return state
  }
}

export default reducer

