import {OWNER_LIST} from './constant.js'

export const combineComplaintFields = (state) => {
  return {
    sessionID: state.session.sessionID,
    ticketid : state.complaint.ticketid,
    ticketdate : state.complaint.ticketdate,
    title: state.complaint.accountname + state.complaint.subcate2,
    contactid: state.user.contactId,
    username: state.user.username,
    accountid: state.complaint.accountid,
    accountname: state.complaint.accountname,
    category: 'ร้องเรียน',
    subcate1:  state.complaint.subcate1,
    subcate2 : state.complaint.subcate2,
    subcate3 : '',
    channel : window.env.channel,
    sufferer: state.complaint.purchaseFields.sufferer,
    productname : state.complaint.purchaseFields.productname,
    productprice : state.complaint.purchaseFields.productprice,
    purchasedate : state.complaint.purchaseFields.purchasedate,
    payment : state.complaint.purchaseFields.payment,
    purchase : state.complaint.purchaseFields.purchase,
    website : state.complaint.purchaseFields.website,
    store : state.complaint.purchaseFields.store,
    problem :state.complaint.problemFields.problem,
    damage : state.complaint.problemFields.damage,
    desc : state.complaint.problemFields.desc,
    request : state.complaint.request,
    ownerid : state.complaint.ownerid,
    ownername: state.complaint.ownername,
    solution: state.complaint.solution,
    imageFile: state.complaint.imageFile,
    public: state.ads.adsFields.public,
    donate: state.ads.adsFields.donate,
    member: state.ads.adsFields.member,
    delegate: state.complaint.purchaseFields.delegate ? 1:0,
    relation: state.complaint.purchaseFields.relation,
  }
}

export const combineAds = (state) => {
  return{
    ticketid : state.complaint.ticketid,
    sufferer: state.user.username,
    public: state.ads.adsFields.public,
    donate: state.ads.adsFields.donate,
    member: state.ads.adsFields.member
  }
}

export const getISODateString = (date) => {
  let offset = date.getTimezoneOffset() * 60000
  return new Date(date - offset).toISOString().slice(0, 10)
}

export const getSummaryList = (state) => {
  if (state.delegate == 0) {
    return [
      { 
        title: 'วันที่ร้องเรียน',
        value: state.ticketdate
      },
      {
        title: 'ชื่อผู้ร้องเรียน',
        value: state.username
      },
      {
        title: 'คู่กรณี',
        value: state.accountname
      },
      {
        title: 'ลักษณะเรื่อง/ประเภทธุรกิจ',
        value: state.subcate2
      },
      {
        title: 'ชื่อ-นามสกุล ผู้เสียหาย',
        value: state.sufferer
      },
      {
        title: 'ชื่อสินค้า/บริการ',
        value: state.productname
      },
      {
        title: 'วิธีการชำระเงิน',
        value: state.payment
      },
      {
        title: 'ราคา',
        value: state.productprice
      },
      {
        title: 'ช่องทางการซื้อสินค้าบริการ',
        value: state.purchase
      },
      {
        title: 'วันที่ซื้อสินค้า/บริการ',
        value: state.purchasedate
      },
      {
        title: 'ปัญหาที่เกิดจากการใช้สินค้า/บริการ',
        value: state.problem
      },
      {
        title: 'มูลค่าความเสียหาย',
        value: state.damage
      },
      {
        title: 'รายละเอียดเกี่ยวกับเหตุการที่เกิดขึ้น',
        value: state.desc
      },
      {
        title: 'สิ่งที่ต้องการความรับผิดชอบ',
        value: state.request
      },
      {
        title: 'วิธีการแก้ไข',
        value: state.solution
      }
    ]  
  } else {
    return [
      { 
        title: 'วันที่ร้องเรียน',
        value: state.ticketdate
      },
      { 
        title: 'ชื่อผู้ร้องเรียนแทน',
        value: state.username
      },
      {
        title: 'ชื่อ-นามสกุล ผู้เสียหาย',
        value: state.sufferer
      },
      {
        title: 'เกี่ยวข้องกับผู้ร้อง',
        value: state.relation
      },
      {
        title: 'คู่กรณี',
        value: state.accountname
      },
      {
        title: 'ลักษณะเรื่อง/ประเภทธุรกิจ',
        value: state.subcate2
      },
      {
        title: 'ชื่อสินค้า/บริการ',
        value: state.productname
      },
      {
        title: 'วิธีการชำระเงิน',
        value: state.payment
      },
      {
        title: 'ราคา',
        value: state.productprice
      },
      {
        title: 'ช่องทางการซื้อสินค้าบริการ',
        value: state.purchase
      },
      {
        title: 'วันที่ซื้อสินค้า/บริการ',
        value: state.purchasedate
      },
      {
        title: 'ปัญหาที่เกิดจากการใช้สินค้า/บริการ',
        value: state.problem
      },
      {
        title: 'มูลค่าความเสียหาย',
        value: state.damage
      },
      {
        title: 'รายละเอียดเกี่ยวกับเหตุการที่เกิดขึ้น',
        value: state.desc
      },
      {
        title: 'สิ่งที่ต้องการความรับผิดชอบ',
        value: state.request
      },
      {
        title: 'วิธีการแก้ไข',
        value: state.solution
      }
    ]  
  }
}

export const getOwnerNameByID = (id) => {
  const results = OWNER_LIST.filter(result => {
    return result.id === id
  })

  if (results.length > 0) {
    return results[0].title
  }

  return ''
}
