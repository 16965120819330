import React from 'react'
import TextField from 'material-ui/TextField'
import './LoginFields.css'

const loginField = (props) => {

  let style = null
  let className ='LoginField font-text field-line'
  if (props.isPopup) {
    style = {
      'height': '70px',
      'overflow': 'hidden',
    }
    className ='LoginField font-text'
  }

  const inputProps={
    'classes': {
      'input': {
        'font-size': '500px'
      }
    },
  }

  return (
    <div>
      <div className ={className}>
        <TextField
          floatingLabelText="อีเมล"
          name="email"
          errorText={props.errors.email}
          onChange={props.onChange}
          value={props.user.email}
          InputProps={inputProps}
          style={style}
        />
      </div>

      <div className ={className}>
        <TextField
          floatingLabelText="รหัสผ่าน"
          type="password"
          name="password"
          onChange={props.onChange}
          errorText={props.errors.password}
          value={props.user.password}
          style={style}
        />
      </div>
    </div>
  )
}

/*
  user

  
*/

export default loginField

