import React from 'react'
import './UpdateContactPopUp.css'
import TextField from 'material-ui/TextField'

const updateContactPopUp = (props) => {

  const {
    email = '',
    phone = ''
  } = props.updateContactData || {}

  const errors  = props.errors || {}

  const phoneError =  errors.phone || ''
  const emailError =  errors.email || ''

  return (
    <div className='UpdateContactPopUp'>
      <div className='UpdateContactPopUp-header'>
        {window.env.updateContactPopUpTitle}
      </div>
      <div className='UpdateContactPopUp-input-wrap'>
        <TextField
          class="TextField"
          floatingLabelText="เบอร์มือถือ"
          errorText={phoneError}
          onChange={(event) => props.onChange(event, 'phone')}
          value={phone}
        />
        <TextField
          class="TextField"
          floatingLabelText="อีเมล์"
          errorText={emailError}
          onChange={(event) => props.onChange(event, 'email')}
          value={email}
        />
      </div>
      <div className='UpdateContactPopUp-button-wrap'>
        <button 
          className='btn btn-primary'
          onClick={props.onClick}
        >{props.buttonName}</button>
      </div>
    </div>
  )
}

export default updateContactPopUp

