import React from 'react'

const menuIcon = (props) => {
  return (
    <button 
      className="navbar-toggler" 
      type="button" 
      data-toggle="collapse" 
      data-target="#navbarCollapse" 
      aria-controls="navbarCollapse" 
      aria-expanded="false" 
      aria-label="Toggle navigation"
    ><span className="navbar-toggler-icon"></span>
    </button>
  )
}

export default menuIcon
