import * as actionTypes from '../actions/actionTypes'
import {updateState} from '../utils'
import { stat } from 'fs'
import * as constant from '../../utils/constant.js'


const initialState = {
  adsFields:{
    public:constant.PUBLIC_OPTION[0],
    donate:constant.DONATE_OPTION[0],
    member:constant.MEMBER_OPTION[0]
  },
  loading: false,
  saveRedirect: false,
}

const saveAds = (state,action) => {
  return updateState(state,{
    public: action.public,
    donate: action.donate,
    member: action.member
  })
}

const callApiFail = (state, action) => {
  return updateState(state, {
    loading: false
  })
}

const submitAdsStart = (state, action) => {
  return updateState(state, {
    loading: true
  })
}

const submitAdsSuccess = (state, action) => {
  return updateState(state, {
    loading: false,
    ticketid: action.ticketid,
    saveRedirect: true
  })
}

const clearRedirect = (state, action) => {
  return updateState(state, {
    saveRedirect: false,
    adsFields:{
      public:constant.PUBLIC_OPTION[0],
      donate:constant.DONATE_OPTION[0],
      member:constant.MEMBER_OPTION[0]
    },
  })
}

const reducer = (state = initialState,action) => {
  switch (action.type){
    case actionTypes.SAVE_ADS:
      return saveAds(state,action)
    
    case actionTypes.CALL_API_FAIL:
      return callApiFail(state, action)
      
    case actionTypes.SUBMIT_ADS_START:
      return submitAdsStart(state, action)

    case actionTypes.SUBMIT_ADS_SUCCESS:
      return submitAdsSuccess(state, action)  

    case actionTypes.CLEAR_REDIRECT:
      return clearRedirect(state, action)
    
    default:
      return state
  }
}

export default reducer
