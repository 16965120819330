import React from 'react'
import {Search} from 'semantic-ui-react'

const SearchForm = (props) => {
  return(
    <Search
      fluid
      loading={props.loading}
      onResultSelect={props.handleResultSelect}
      onSearchChange={props.handleSearchChange}
      showNoResults = {false}
      results= {props.results}
      value={props.value}
      input={{ fluid: true }}
      {...props}
    />

  )
}

export default SearchForm