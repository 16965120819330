import React, {Component} from 'react'
import {connect} from 'react-redux'
import RegisterCard from '../../components/RegisterCard/RegisterCard'

import * as action from '../../store/actions/index'
import Loader from '../../components/UI/Loader/Loader'
import * as constant from './constant.js'
import Condition from '../../components/information/condition/condition.js'
import Modal from '../../components/UI/Modal/Modal.js'


class Register extends Component {

  componentDidUpdate() {
    if (this.props.registerError === null && this.props.registerSuccess) {
      this.props.onClearRegister()
      this.props.onLogin(this.state.user.email, this.state.user.password, this.props.sessionID)
    }

    if (this.props.contactId !== null) {
      if (this.props.redirectAfterLogin) {
        window.location.replace('/')
      }

      if (this.props.callback) {
        this.props.callback()
      }
    }
  }

  state = {
    errors: {
      firstname:'',
      lastname:'',
      name:'',
      email: '',
      password: '',
      idcard: '',
      phone: '',
      registerError: '',
      condition: ''
    },
    user: {
      firstname:'',
      lastname:'',
      email: '',
      password: '',
      idcard: '',
      phone: '',
      condition: false,
    },
    showCondition: false
  }



  onChangeHandler = (event) => {
    const field = event.target.name
    const user = this.state.user
    if (field === 'condition') {
      user[field] = !user[field]
    }else {
      user[field] = event.target.value
    }

    this.setState({
      user: user
    })
  }

  onRegisterSubmitted = (event) => {
    event.preventDefault()
    if (this.checkFieldsHaveNoError()){
      this.props.onRegister(this.state.user, this.props.sessionID)
    }
  }

  checkFieldsHaveNoError = () => {
    let errors = Object.assign({}, this.state.errors)
    errors = {}
    this.setState({
      errors: {},
    })

    if(this.state.user.firstname.trim().length === 0){
      errors.firstname = constant.FIRSTNAME_WARN
    }
    if(this.state.user.lastname.trim().length === 0){
      errors.lastname = constant.LASTNAME_WARN
    }
    if(this.state.user.email.trim().length === 0){
      errors.email = constant.EMAIL_WARN
    }
    if(this.state.user.password.trim().length === 0){
      errors.password = constant.PASSWORD_WARN
    }
    if(this.state.user.phone.trim().length === 0){
      errors.phone = constant.MOBILE_WARN
    }

    if(this.state.user.idcard.trim().length !== 13) {
      errors.idcard = constant.IDCARD_WARN
    }

    if (!this.state.user.condition) {
      errors.condition = constant.CONDITION_WARN
    }

    if (JSON.stringify(errors) !== '{}') {
      this.setState({
        errors,
      })
      return false
    }
    return true
  }

  onConditionClickedHandler = () => {
    this.setState({
      showCondition: true
    })
    return false
  }

  onBackDropClickedHandler = () => {
    this.setState({
      showCondition: false
    })
  }

  render() {
    return (
      <div>
        <Modal show = {this.state.showCondition} modalClosed = {this.onBackDropClickedHandler}>
          <Condition/>
        </Modal>

        <RegisterCard
          title = {constant.SIGNUP_TITLE}
          registerError = {this.props.registerError}
          errors = {this.state.errors}
          user = {this.state.user}
          onChange = {this.onChangeHandler}
          onRegisterSubmitted = {this.onRegisterSubmitted}
          onConditionClicked = {this.onConditionClickedHandler}
          {...this.props}
        />
        <Loader show = {this.props.registerLoading || this.props.loginLoading} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    sessionID: state.session.sessionID,
    registerSuccess: state.register.registerSuccess,
    registerError: state.register.registerError,
    registerLoading: state.register.loading,
    loginLoading: state.user.loading,
    contactId: state.user.contactId
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onClearRegister: () => dispatch(action.clearRegister()),
    onRegister: (user, sessionID) => dispatch(action.register(user, sessionID)),
    onLogin: (email, password, sessionID) => dispatch(action.login(email, password, sessionID))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)
