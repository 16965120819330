import React from 'react'

const loader = (props) => {
  const loadingStyle = props.show ? {display:'block'}:{display:'none'}
  return (
    <div  style = {loadingStyle}>
      <div className=" img-middle" style={{'backgroundColor': 'rgba(0,0,0,.6)', 'z-index': 600}}>
        <img 
          className = "img-fluid img-loader" 
          src = "./img/color-loading.gif"
          alt = "loader"
        />
      </div>
	  </div>
  )
}

export default loader

