import React from 'react'
import SelectForm from '../../../components/UI/Forms/SelectForm/SelectForm.js'

const Ads = (props) => {
  let linkDonate = null
  if(props.checkDonate){
    linkDonate=(
      <div className="float-right">
          ดูวิธีการบริจาคได้<a href={window.env.ADS_DONATION_LINK} target='_blank'> ที่นี้</a> 
      </div>
    )
  }

  let linkMember = null
  if(props.checkMember){
    linkMember=(
      <div className="float-right">
          ดูวิธีการสมัครได้<a href='https://chaladsue.com/howtoregister' target='_blank'> ที่นี้</a>
      </div>
    )
  }

  return (
    <div>
      <SelectForm
        id = "public"
        label = "ยินดีเผยเพร่ข้อมูลหรือไม่"
        value = {props.adsfields.public}
        onChange = {props.onChange}
        options = {props.publicOptions}
        error = {false}
      />
      <SelectForm
        id = "donate"
        label = "สนใจร่วมบริจาคให้กับทางมูลนิธิฯหรือไม่"
        value = {props.adsfields.donate}
        onChange = {props.onChange}
        options = {props.donateOptions}
        error = {false}
      />
      {linkDonate}
      <br/>
      <SelectForm
        id = "member"
        label = "สนใจสมัครสมาชิกฉลาดซื้อหรือไม่"
        value = {props.adsfields.member}
        onChange = {props.onChange}
        options = {props.memberOptions}
        error = {false}
      />
      {linkMember}
    </div>
  )
}
export default Ads
