import React from 'react'

const textForm = (props) => {
  let showError = 'none'  
  if (props.error) {
    showError = ''
  }
  return (            
    <div className="  form-group row">
      <label 
        className="col-12 col-sm-12 col-form-label">
        {props.label}<span className = "font-red"> *</span>
      </label>
      <div className="col-12 col-sm-12">
        <input 
          type="text" 
          className="form-control" 
          id = {props.id}
          placeholder = {props.placeholder} 
          value = {props.value} 
          onChange = {props.onChange} 
          required/>
      </div>
      <div className="col-12 col-sm-12">
        <p id='err_productname' className="font-red" style={{display: showError}}>โปรดกรอกข้อมูล</p>                    
      </div>         
    </div>
  )
}

export default textForm
