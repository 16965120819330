import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Route, Switch, Redirect, withRouter} from 'react-router-dom'
import axios from './axios-vtiger.js'
import * as actions from './store/actions/index' 
import './Cardcss.css'

import Layout from './hoc/Layout/Layout.js'
import withErrorHandler from './hoc/withErrorHandler/withErrorHandler.js'
import Login from './containers/Login/Login'
import Register from './containers/Register/Register'
import Dashboard from './containers/Dashboard/Dashboard'
import Accept from './containers/Complaint/AcceptPage/AcceptPage'

import Search from './containers/Complaint/Search/Search'
import Category from './containers/Complaint/Category/Category.js'
import Purchase from './containers/Complaint/Purchase/Purchase.js'
import Problem from './containers/Complaint/Problem/Problem.js'
import UploadImage from './containers/Complaint/UploadImage/UploadImage.js'
import Request from './containers/Complaint/Request/Request.js'
import Summary from './containers/Complaint/Summary/Summary.js'
import TicketSummary from './containers/Dashboard/TicketSummary/TicketSummary.js'
import Channel from './containers/Information/Channel/Channel.js'
import Conditions from './containers/Information/Conditions/Conditions.js'
import Contact from './containers/Information/Contact/Contact.js'
import Account from './containers/Complaint/Account/Account.js'
import Ads from './containers/Complaint/Adspage/Adspage.js'


class App extends Component {

  componentDidMount() {
    this.props.checkLogin()
    if (this.needRedirectToDashboard()) {
      window.location.replace('/dashboard')
    }
  }

  needRedirectToDashboard = () => {
    const dontRedirectList = [
      '/dashboard',
      '/condition',
      '/contact',
      '/channel',
      '/signin',
      '/signup',
    ]
    return !dontRedirectList.includes(window.location.pathname)
  }

  loginWithRedirect = (props) => {
    return (
      <Login 
        redirectAfterLogin={true}
        onSignUpClicked={this.props.onSignUpClicked}
        title="เข้าสู่ระบบ"
      />
    )
  }

  registerWithRedirect = (props) => {
    return (
      <Register 
        redirectAfterLogin={true}
      />
    )
  }


  render() {

    const loggedInRoutes = (
      <Switch>
        <Route path = "/signin" exact component = {this.loginWithRedirect}/>
        <Route path = "/signup" exact component = {this.registerWithRedirect}/>
        <Route path = "/dashboard" exact component = {Dashboard}/>
        <Route path = "/accept" exact component = {Accept}/>
        <Route path = "/search"  component = {Search}/>
        <Route path = "/category" exact component = {Category}/>
        <Route path = "/purchase" exact component = {Purchase}/>
        <Route path = "/problem" exact component = {Problem}/>
        <Route path = "/upload_image" exact component = {UploadImage}/>
        <Route path = "/request" exact component = {Request}/>
        <Route path = "/summary" exact component = {Summary}/>
        <Route path = "/ticket_summary" exact component = {TicketSummary}/>
        <Route path = "/channel" exact component = {Channel}/>
        <Route path = "/contact" exact component = {Contact}/>
        <Route path = "/condition" exact component = {Conditions}/>
        <Route path = "/account" exact component = {Account}/>
        <Route path = "/ads" exact component = {Ads}/>
        <Redirect to = "/dashboard"/>
      </Switch>
    )

    return (
      <div>
        <Layout>
          {loggedInRoutes}
        </Layout>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isLogin: state.user.contactId !== null,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    checkLogin: () => dispatch(actions.checkLogin()),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(App, axios)))
