import axios from 'axios'

const instance = axios.create({
  baseURL: window.env.API_URL,
  timeout: 30000
})


instance.interceptors.request.use(request => {
  console.log(request)
  return request
}, error => {
  return Promise.reject(error)
})

instance.interceptors.response.use(response => {
  console.log(response)
  return response
}, error => {
  return Promise.reject(error)
})

export default instance

