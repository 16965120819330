import React, {Component} from 'react'
import './Modal.css'
import BackDrop from '../BackDrop/BackDrop'


class Modal extends Component {

  shouldComponentUpdate(nextProps, nextState) {
    const showChanged = nextProps.show !== this.props.show
    const childrenChanged = nextProps.children !== this.props.children
    return showChanged || childrenChanged
  }

  render() {
    return (
      <div>
        <BackDrop show = {this.props.show} clicked = {this.props.modalClosed}/>
        <div 
          className = "Modal"
          style = {{
            transform: this.props.show ? 'translateY(0)' : 'translateY(-100vh)',
            opacity: this.props.show ? '1': '0',
            height: this.props.error ? '20%' : '60%',
            overflow: this.props.error ? 'none' : 'scroll'
          }}
        >{this.props.children}</div>
      </div>

    )
  }
}

export default Modal
    
