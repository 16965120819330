import {updateState} from '../utils'
import * as actionTypes from '../actions/actionTypes'

const initialState = {
  sessionID: '',
  loading: false,
  error: null
}

const getSessionStart = (state, action) => {
  return updateState(state, {
    loading: true
  })
}

const getSessionFail = (state, action) => {
  return updateState(state, {
    loading: false,
    error: action.error
  })
}

const getSessionSuccess = (state, action) => {
  return updateState(state, {
    loading: false,
    sessionID: action.sessionID
  })
}


const reducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.GET_SESSION_START:
      return getSessionStart(state, action)

    case actionTypes.GET_SESSION_SUCCESS:
      return getSessionSuccess(state, action)    

    case actionTypes.GET_SESSION_FAIL:
      return getSessionFail(state, action)    

    default:
      return state
  }

}

export default reducer

