import React, {Component} from 'react'
import {connect} from 'react-redux'
import Loader from '../../../components/UI/Loader/Loader'
import Category from '../../../components/Complaint/Category/Category.js'
import StepBar from '../../../hoc/StepBar/StepBar.js'
import * as action from '../../../store/actions/index'
import * as constant from '../../../utils/constant.js'

class CategoryPage extends Component {

  componentDidMount() {
    if (this.props.subcate2) {
      this.setState({
        enable: true
      })
    }

    if (this.props.subcate2List.length === 0) {
      this.props.onGetSubcate2(this.props.sessionID)
    }

    if (this.props.subcate1 !== '') {
      this.props.onClearSubcate1()
    }


  }

  componentDidUpdate() {
    if (this.props.subcate1 !== '') {
      this.props.history.push('/purchase')
    }
  }

  state = {
    enable : false,
  }

  onBack = () => {
    this.props.history.push('/search')
  }
  
  onNext = () => {
    this.props.onGetSubcate1(this.props.subcate2)
  }

  handleClick = (event) => {
    if (this.props.subcate2 === event.target.value) {
      this.setState({
        enable: false
      })
      this.props.onSaveSubcate2(null)
    } else {
      this.setState({
        enable: true
      })
      this.props.onSaveSubcate2(event.target.value)
    }
  }

  css = (name) =>{
    if (this.props.subcate2 === name) {
	  	return 'rgb(0, 188, 212)'
	  }
    return '#ffffff'
  }
    
  render() {
    return (
      <StepBar
        header = {constant.CATEGORY_HEADER}
        onBack = {this.onBack} 
        enable = {this.state.enable}
        onNext = {this.onNext} 
      >
        <Category 
          sub={this.props.subcate2List} 
          css={this.css} 
          clicked={this.handleClick}/>
        <Loader show = {this.props.loading} />
      </StepBar>

    )
  }
}

const mapStateToProps = (state) => {
  return {
    sessionID: state.session.sessionID,
    subcate1: state.complaint.subcate1,
    loading: state.complaint.loading,
    subcate2List: state.complaint.subcate2List,
    subcate2: state.complaint.subcate2,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClearSubcate1: () => dispatch(action.clearSubcate1()),
    onGetSubcate2: (sessionID) => dispatch(action.getSubcate2(sessionID)),
    onSaveSubcate2: (subcate2) => dispatch(action.saveSubcate2(subcate2)),
    onGetSubcate1: (subcate2) => dispatch(action.getSubcate1(subcate2)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryPage)
