import React, { Component } from 'react'
import './UploadImageBox.css'

const uploadImageBox = (props) => {

  let image = null
  if (props.fileType === 'application/pdf') {
    image = (
      <embed  
        src={props.file}
      />
    )

  } else {
    image = (
      <img src = {props.file}/>
    )
  }

  const uploadedImage = (
    <div className = "UploadedImage">
      <label htmlFor= {props.id}>
        {image}
      </label>
      <img className = "DeleteIcon" src = './img/x-mark-icon.svg' onClick = {props.onRemove} id = {props.id}/>
      <input className = "HiddenInput" id = {props.id} type = "file" accept="image/*" onChange = {props.onChange}/>
    </div>
  )

  const emptyImage = (
    <div className = "EmptyImageBox">
      <label htmlFor= {props.id}>
        <div>
          <img src = './img/camera-icon.svg'/>
          Add Photo / PDF
        </div>
      </label>
      <input className = "HiddenInput" id = {props.id} type = "file" accept="image/* , application/pdf" onChange = {props.onChange}/>
    </div>
  )

  return (
    props.file ? uploadedImage : emptyImage
  )
}

export default uploadImageBox

