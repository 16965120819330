import React from 'react'
import Summary from './Summary/Summary.js'

const summaries = (props) => {

  let summaryList = props.summaryList
  if (!props.hasRequest) {
    summaryList = props.summaryList.slice(0, -1)
  }

  return summaryList.map( val => {
    return (
      <Summary
        key = {val.title}
        title = {val.title}
        value = {val.value}
      />
    )
  })
}

export default summaries
