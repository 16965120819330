import React from 'react'
import {Affix} from 'antd'
import './Bar.css'

const Bar = (props) => {

  let style = null
  if (props.isReadOnly) {
    style = {
      'display': 'none'
    }
  } 

  let nextButtonStyle = null
  if (props.hasSubmit || props.isReadOnly) {
    nextButtonStyle = {
      'display': 'none'
    }
  }

  let button = (
    <button
      style = {nextButtonStyle}
      type = "button"
      className="btn btn-primary float-right"
      disabled = {!props.enable}
      onClick = {props.onNext}
    >ถัดไป <i className="fa fa-angle-right"></i>                  
    </button>
  )


  if (props.isLastPage || props.isReadOnly) {
    button = null
  }

  return (
    <Affix>
      <div className="z-index text-center font-all">
        <div className = "row">
          <div className = "col-3">
            <button 
              style = {style}
              type="button" 
              className="btn  float-left btnback" 
              onClick = {props.onBack}
            >
              <i className="fa fa-angle-left"></i> ย้อนหลัง
            </button>
          </div>
          <div className = "col-6 BarHeader">{props.header}</div>
          <div className = "col-3">
            {button}
          </div>									
        </div>
      </div>
    </Affix>
  )
}
  
export default Bar
