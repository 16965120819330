import React from 'react'
import './Ticket.css'
import * as constant from '../../../../utils/constant.js'

const ticket = (props) => {

  let statusColor = 'TicketNormal'
  if (props.ticket.status_th === constant.TICKET_STATUS_OPEN) {
    statusColor = 'TicketOpen'
  }else if (props.ticket.status_th === constant.TICKET_STATUS_CLOSE) {
    statusColor = 'TicketClose'
  }

  return (
    <div className="cardMain cards-list" onClick = {props.onClick} id = {props.id}>
      <p>{props.ticket.accountname}</p>
      <p>{props.ticket.ticketdate}</p>
      <p>
        <span className="float-left">{props.ticket.ticketno}</span>
        <span className={'text-right ' + statusColor}>สถานะ: {props.ticket.status_th}</span>
      </p>
    </div>
  )
}

export default ticket

/*


    <div className="cardMain" >

      </div>

onClick={() => this.nextEdit(props.ticket.ticketid)} 
style={{backgroundColor: this.myColor(props.ticket.ticketid)}} 

*/
