import React, {Component} from 'react'
import Condition from '../../../components/information/condition/condition.js'

class Conditions extends Component {
  render() {
    return (
      <Condition/>
    )
  }
}
export default Conditions
