import React from 'react'
import { splitBsPropsAndOmit } from 'react-bootstrap/lib/utils/bootstrapUtils'
import {Link} from 'react-router-dom'

import MenuIcon from '../MenuIcon/MenuIcon'
import NavigationItems from '../NavigationItems/NavigationItems'
import './ToolBar.css'

const navigationBar = (props) => {

  return (
    <header>
      <nav className="navbar navbar-expand-md navbar-dark bg-dark">
        <div>
          <a href='/dashboard'>
            <img src="./img/home.png" className="ToolBar-HomeImage"/>
          </a>
          {props.username !== null ? <Link to="/" className="navbar-brand">{props.username}</Link>: <Link to="/signin" className="navbar-brand">เข้าสู่ระบบ</Link>}
        </div>
        <MenuIcon/>
        <NavigationItems
          username = {props.username}
          onLogout = {props.onLogout}
        />
      </nav>
    </header>
    /*

        //{props.hasUser ? <User username = {props.username}/> : null}

    <header>
      <nav className="navbar navbar-expand-md navbar-dark bg-dark">
        <Link to="/" className="navbar-brand">Consumer Complaint</Link>
        <button 
          className="navbar-toggler" 
          type="button" 
          data-toggle="collapse" 
          data-target="#navbarCollapse" 
          aria-controls="navbarCollapse" 
          aria-expanded="false" 
          aria-label="Toggle navigation"
        ><span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav mr-auto">

            <li className="nav-item">
              <Link 
                to="/conditions" 
                className="nav-link" 
                style={{color: 'white'}}>
                <span className="sr-only">(current)</span>เงื่อนไขการร้องทุกข์ออนไลน์
              </Link>
            </li>

            <li className="nav-item">
              <Link 
                to="/channel" 
                className="nav-link" 
                style={{color: 'white'}}>
                ช่องทางการร้องทุกข์อื่นๆ
              </Link>
            </li>

            <li className="nav-item">
              <Link 
                to="/contact" 
                className="nav-link" 
                style={{color: 'white'}}>
                ติดต่อเรา
              </Link>
            </li>
          </ul>
          <form className="form-inline mt-2 mt-md-0 top-bar-right">
            <div>
              {
                Username.isUserHave() && 
                  <div>
                    <div className="my-2 my-sm-0 username-header">
                      {Username.getUsername()}
                    </div>
                    <Link to="/logout">
                      <button 
                        className="btn btn-light my-2 my-sm-0" 
                        type="submit"> 
                        ออกจากระบบ
                      </button>
                    </Link>
                  </div>
              }
            </div>
          </form>
        </div>
      </nav>
    </header>

*/

  )
}

export default navigationBar

