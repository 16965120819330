import React from 'react'

const selectForm = (props) => {

  let showError = 'none'  
  if (props.error) {
    showError = ''
  }

  const options = props.options.map(option => {
    return <option key = {option}>{option}</option>
  })
  

  return (
    <div className="form-group row">
      <label 
        className="col-12 col-sm-12 col-form-label">
        {props.label}<span className="font-red"> *</span>
      </label>
      <div className="col-12 col-sm-12">
        <select className="form-control" id={props.id} value={props.value} onChange={props.onChange} required>
          {options}
        </select>
      </div>

      <div className="col-12 col-sm-12">
        <p id='err_productname' className="font-red" style={{display: showError}}>โปรดกรอกข้อมูล</p>                    
      </div>       
    </div>


  )
}

export default selectForm


//<option value=""></option>
