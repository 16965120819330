import React from 'react'
import NavigationItem from './NavigationItem/NavigationItem'
import User from '../User/User'

const navigationItems = (props) => {
  return (
    <div className="collapse navbar-collapse" id="navbarCollapse">
      <ul className="navbar-nav mr-auto">
        <NavigationItem
          name = "เงื่อนไขการร้องทุกข์ออนไลน์"
          path = "/condition"
        />
        <NavigationItem
          name = "ช่องทางการร้องทุกข์อื่นๆ"
          path = "/channel"
        />
        <NavigationItem
          name = "ติดต่อเรา"
          path = "/contact"
        />
      </ul>
      {props.username !== null ? <User {...props}/> : null}
    </div>


  )
}

export default navigationItems

  
