import * as actionTypes from './actionTypes'
import axios from '../../axios-vtiger.js'

export const getSession = () => {
  return dispatch => {
    dispatch(getSessionStart())
    callGetSessionAPI(dispatch)
  }
}

export const sessionMiddleware = (dispatch, count, callApi) => {

  const URI = '?function=connect'
  axios.get(URI)
    .then(response => {
      if (response.data) {
        dispatch(getSessionSuccess(response.data.sessionid))
        callApi(response.data.sessionid, count+1)
        return
      }
      dispatch(getSessionFail('get session error'))
    })
}

export const callGetSessionAPI = (dispatch) => {
  const URI = '?function=connect'
  axios.get(URI)
    .then(response => {
      if (response.data) {
        dispatch(getSessionSuccess(response.data.sessionid))
        return
      }
      dispatch(getSessionFail('get session error'))
    })
}

const getSessionStart = () => {
  return {
    type: actionTypes.GET_SESSION_START,
  }
}

const getSessionSuccess = (sessionID) => {
  return {
    type: actionTypes.GET_SESSION_SUCCESS,
    sessionID: sessionID
  }
}

const getSessionFail = (error) => {
  return {
    type: actionTypes.GET_SESSION_FAIL,
    error: error
  }
}

