import React from 'react'
import TextField from 'material-ui/TextField'
import './RegisterFields.css'

const registerFields = (props) => {

  let conditionError = null
  if (props.errors.condition !== '') {
    conditionError = (
      <p style = {{color: 'red'}}>{props.errors.condition}</p>
    )
  }


  return (
    <div>
      <div className="RegisterField field-line">
        <TextField
          floatingLabelText="ชื่อจริง"
          name="firstname"
          errorText={props.errors.firstname}
          onChange={props.onChange}
          value={props.user.firstname}
        />
      </div>
      <div className="RegisterField field-line">
        <TextField
          floatingLabelText="นามสกุล"
          name="lastname"
          errorText={props.errors.lastname}
          onChange={props.onChange}
          value={props.user.lastname}
        />
      </div>
      <div className="RegisterField field-line">
        <TextField
          floatingLabelText="เบอร์มือถือ"
          name="phone"
          errorText={props.errors.phone}
          onChange={props.onChange}
          value={props.user.phone}
        />
      </div>
      <div className="RegisterField field-line">
        <TextField
          floatingLabelText="อีเมล"
          name="email"
          errorText={props.errors.email}
          onChange={props.onChange}
          value={props.user.email}
        />
      </div>

      <div className="RegisterField field-line">
        <TextField
          floatingLabelText="รหัสผ่าน"
          type="password"
          name="password"
          onChange={props.onChange}
          errorText={props.errors.password}
          value={props.user.password}
        />
      </div>
      <div className="RegisterField field-line">
        <TextField
          floatingLabelText="เลขที่บัตรประชาชน"
          name="idcard" 
          errorText={props.errors.idcard}
          onChange={props.onChange}
          value={props.user.idcard}
        />
      </div>
      <div>
        <input 
          type="checkbox" 
          name="condition"
          onChange={props.onChange}
          checked = {props.user.condition}
        />
        &nbsp;&nbsp;ยอมรับ <a href = "#" onClick={props.onConditionClicked}>ข้อกำหนดและเงื่อนไข</a>
      </div>
      <br/>
      {conditionError}
    </div>
  )
}

export default registerFields

