import * as actionTypes from '../actions/actionTypes'
import {updateState} from '../utils'


const initialState = {
  loading:false,
  registerError: null,
  registerSuccess: false
}


const registerStart = (state, action) => {
  return updateState(state, {loading: true})
}

const registerFail = (state, action) => {
  return updateState(state, {loading: false, registerError: action.registerError})
}

const registerSuccess = (state, action) => {
  return updateState(state, {
    loading: false, 
    registerError: null,
    registerSuccess: true
  })
}

const clearRegister = (state, aciton) => {
  return updateState(state, {
    loading:false,
    registerError: null,
    registerSuccess: false
  })
}

const reducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.REGISTER_START: 
      return registerStart(state, action)

    case actionTypes.REGISTER_SUCCESS:
      return registerSuccess(state, action)

    case actionTypes.REGISTER_FAIL:
      return registerFail(state, action)
    
    case actionTypes.CLEAR_REGISTER:
      return clearRegister(state, action)

    default:
      return state
  }

}

export default reducer

