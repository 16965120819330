import React, { Component } from 'react'
import {connect} from 'react-redux'
import Summaries from '../../../components/Complaint/Summaries/Summaries.js'
import * as constant from '../../../utils/constant.js'
import {getSummaryList, combineComplaintFields} from '../../../utils/utils.js'
import StepBar from '../../../hoc/StepBar/StepBar.js'
import * as actions from '../../../store/actions/index.js'


class TicketSummary extends Component {

  state = {
    summaryList: getSummaryList(combineComplaintFields(this.props))
  }

  componentDidUpdate() {
    if (!this.props.complaint.ticketid) {
      this.props.history.goBack()
    }
  }

  onButtonClicked = () => {
    this.props.onClearSummaryComplaint()
  }

  render() {

    return (
      <StepBar
        header = {constant.SUMMARY_HEADER}
        isReadOnly
      >
        <div className = "SummaryContainer">
          <Summaries 
            summaryList = {this.state.summaryList}
            hasRequest
          />
        </div>
        <br/>
        <button 
          type="button" 
          className="btn btnback float-left" 
          onClick = {this.onButtonClicked}
        >
          กลับไปหน้าหลัก
        </button>	
      </StepBar>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClearSummaryComplaint: () => dispatch(actions.clearSummaryComplaint())
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(TicketSummary)
