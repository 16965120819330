export const SEARCH_HEADER = '(1/6) ค้นหาคู่กรณี'
export const NO_RESULT_FOUND = 'no result found.'

export const CATEGORY_HEADER = '(2/6) ระบุลักษณะเรื่อง/ประเภทธุรกิจ'

export const PURCHASE_HEADER = '(3/6) รายละเอียดการซื้อสินค้า/บริการ'
export const PURCHASE_TITLE = 'กรุณาระบุการซื้อสินค้าหรือใช้บริการจาก ' 

export const PROBLEM_HEADER = '(4/6) รายละเอียดเรื่องร้องเรียน'
export const PROBLEM_TITLE = 'กรุณาระบุรายละเอียดลักษณะปัญหาที่พบจาก '

export const UPLOAD_IMAGE_HEADER = '(5/6) แนบรูปภาพประกอบ'
export const UPLOAD_IMAGE_TITLE = 'สามารถแนบภาพถ่าย/PDF เกี่ยวกับเรื่องร้องเรียน'

export const REQUEST_HEADER = '(6/6) สิ่งที่ต้องการความรับผิดชอบ'
export const REQUEST_TITLE = 'สิ่งที่ต้องการความรับผิดชอบจาก '

export const SUBMIT_HEADER = '(7/7) ระบุหน่วยงานจังหวัดที่ส่งเรื่อง'
export const SUBMIT_TITLE = 'เพื่อให้การดำเนินการต่ออย่างมีประสิทธิภาพกรุณาระบุหน่วยงานประจำจังหวัดเพื่อรับผิดชอบ'

export const SUMMARY_HEADER = 'สรุปรายละเอียดเรื่องร้องเรียน'
export const SUMMARY_TITLE = 'กรุณาตรวจสอบข้อมูลเรื่องร้องเรียนของท่านก่อนส่งเรื่องหากมีการส่งเรื่องจะไม่สามารถแก้ไขข้อมูลภายหลัง'

export const PAYMENT_OPTION = [
  '1. เงินสด',
  '2. บัตรเครดิต',
  '3. บัตรเดบิต',
  '4. โอนเงิน',
  '5. ไม่เสียค่าใช้จ่าย'
]


export const PROBLEM_LIMIT_DESC_WARN = 'กรุณาสรุปรายละเอียดเรื่องร้องเรียน ไม่เกิน 1000 ตัวอักษร'

export const NOT_GIVEN_ACCOUNTNAME = '-- ไม่ระบุคู่กรณี --'

export const UPLOAD_FILE_LIST = [
  {
    file: null,
    id: 'file_input_1'
  },
  {
    file: null,
    id: 'file_input_2'
  },
  {
    file: null,
    id: 'file_input_3'
  },
  {
    file: null,
    id: 'file_input_4'
  },
  {
    file: null,
    id: 'file_input_5'
  }
]

export const CONNECTION_ERROR = 'ไม่สามารถเชื่อมต่อได้'
export const API_ERROR = 'เกิดปัญหาข้อผิดพลาด'

export const PUBLIC_OPTION = [
  'ยินดี',
  'ยินดี แต่ไม่ขอเปิดเผยชื่อ',
  'ไม่ยินดีที่จะเปิดเผย'
]

export const DONATE_OPTION = [
  'ร่วมบริจาค',
  'ไม่สนใจ',
]

export const MEMBER_OPTION = [
  'สมัครให้ตนเอง',
  'ไม่สนใจ',
]

export const TICKET_STATUS_CLOSE = 'ปิด'
export const TICKET_STATUS_OPEN = 'เปิด'

export const OWNER_LIST = [
  {title: 'กรุงเทพมหานคร', id: '20x3'},
  {title: 'กระบี่', id: '20x177'},
  {title: 'กาญจนบุรี', id: '20x166'},
  {title: 'กาฬสินธุ์', id: '20x154'},
  {title: 'กำแพงเพชร', id: '20x120'},
  {title: 'ขอนแก่น', id: '20x147'},
  {title: 'จันทบุรี', id: '20x158'},
  {title: 'ฉะเชิงเทรา', id: '20x159'},
  {title: 'ชลบุรี', id: '20x160'},
  {title: 'ชัยนาท', id: '20x122'},
  {title: 'ชัยภูมิ', id: '20x195'},
  {title: 'เชียงใหม่', id: '20x112'},
  {title: 'เชียงราย', id: '20x115'},
  {title: 'ชุมพร', id: '20x176'},
  {title: 'ตรัง', id: '20x202'},
  {title: 'ตราด', id: '20x161'},
  {title: 'ตาก', id: '20x183'},
  {title: 'นครนายก', id: '20x197'},
  {title: 'นครปฐม', id: '20x172'},
  {title: 'นครพนม', id: '20x192'},
  {title: 'นครราชสีมา', id: '20x194'},
  {title: 'นครศรีธรรมราช', id: '20x199'},
  {title: 'นครสวรรค์', id: '20x187'},
  {title: 'นนทบุรี', id: '20x210'},
  {title: 'นราธิวาส', id: '20x180'},
  {title: 'น่าน', id: '20x118'},
  {title: 'บึงกาฬ', id: '20x193'},
  {title: 'บุรีรัมย์', id: '20x149'},
  {title: 'ปทุมธานี', id: '20x209'},
  {title: 'ประจวบคีรีขันธ์', id: '20x167'},
  {title: 'ปราจีนบุรี', id: '20x162'},
  {title: 'ปัตตานี', id: '20x178'},
  {title: 'เพชรบุรี', id: '20x168'},
  {title: 'เพชรบูรณ์', id: '20x184'},
  {title: 'แพร่', id: '20x117'},
  {title: 'พระนครศรีอยุธยา', id: '20x204'},
  {title: 'พะเยา', id: '20x116'},
  {title: 'พังงา', id: '20x201'},
  {title: 'พัทลุง', id: '20x203'},
  {title: 'พิจิตร', id: '20x121'},
  {title: 'พิษณุโลก', id: '20x119'},
  {title: 'ภูเก็ต', id: '20x200'},
  {title: 'มหาสารคาม', id: '20x155'},
  {title: 'แม่ฮ่องสอน', id: '20x145'},
  {title: 'มุกดาหาร', id: '20x191'},
  {title: 'ยโสธร', id: '20x153'},
  {title: 'ยะลา', id: '20x179'},
  {title: 'ร้อยเอ็ด', id: '20x148'},
  {title: 'ระนอง', id: '20x198'},
  {title: 'ระยอง', id: '20x165'},
  {title: 'ราชบุรี', id: '20x169'},
  {title: 'ลพบุรี', id: '20x205'},
  {title: 'เลย', id: '20x190'},
  {title: 'ลำปาง', id: '20x114'},
  {title: 'ลำพูน', id: '20x113'},
  {title: 'ศรีสะเกษ', id: '20x196'},
  {title: 'สกลนคร', id: '20x157'},
  {title: 'สงขลา', id: '20x174'},
  {title: 'สตูล', id: '20x181'},
  {title: 'สมุทรปราการ', id: '20x164'},
  {title: 'สมุทรสงคราม', id: '20x170'},
  {title: 'สมุทรสาคร', id: '20x173'},
  {title: 'สระแก้ว', id: '20x163'},
  {title: 'สระบุรี', id: '20x206'},
  {title: 'สิงห์บุรี', id: '20x208'},
  {title: 'สุโขทัย', id: '20x185'},
  {title: 'สุพรรณบุรี', id: '20x171'},
  {title: 'สุราษฎร์ธานี', id: '20x175'},
  {title: 'สุรินทร์', id: '20x150'},
  {title: 'หนองคาย', id: '20x189'},
  {title: 'หนองบัวลำภู', id: '20x146'},
  {title: 'อ่างทอง', id: '20x207'},
  {title: 'อำนาจเจริญ', id: '20x152'},
  {title: 'อุดรธานี', id: '20x156'},
  {title: 'อุตรดิตถ์', id: '20x186'},
  {title: 'อุทัยธานี', id: '20x188'},
  {title: 'อุบลราชธานี', id: '20x151'},
]

/*
export const OWNER_LIST = [
  {title:'กรุงเทพมหานคร', id: '19x3'},
  {title:'กระบี่', id: '19x96'},
  {title:'กาญจนบุรี', id: '19x85'},
  {title:'กาฬสินธุ์', id: '19x57'},
  {title:'กำแพงเพชร', id: '19x39'},
  {title:'ขอนแก่น', id: '19x50'},
  {title:'จันทบุรี', id: '19x69'},
  {title:'ฉะเชิงเทรา', id: '19x70'},
  {title:'ชลบุรี', id: '19x71'},
  {title:'ชัยนาท', id: '19x41'},
  {title:'ชัยภูมิ', id: '19x67'},
  {title:'เชียงใหม่', id: '19x31'},
  {title:'เชียงราย', id: '19x34'},
  {title:'ชุมพร', id: '19x95'},
  {title:'ตรัง', id: '19x105'},
  {title:'ตราด', id: '19x72'},
  {title:'ตาก', id: '19x43'},
  {title:'นครนายก', id: '19x77'},
  {title:'นครปฐม', id: '19x91'},
  {title:'นครพนม', id: '19x64'},
  {title:'นครราชสีมา', id: '19x66'},
  {title:'นครศรีธรรมราช', id: '19x102'},
  {title:'นครสวรรค์', id: '19x47'},
  {title:'นนทบุรี', id: '19x84'},
  {title:'นราธิวาส', id: '19x99'},
  {title:'น่าน', id: '19x37'},
  {title:'บึงกาฬ', id: '19x65'},
  {title:'บุรีรัมย์', id: '19x52'},
  {title:'ปทุมธานี', id: '19x83'},
  {title:'ประจวบคีรีขันธ์', id: '19x86'},
  {title:'ปราจีนบุรี', id: '19x73'},
  {title:'ปัตตานี', id: '19x97'},
  {title:'เพชรบุรี', id: '19x87'},
  {title:'เพชรบูรณ์', id: '19x44'},
  {title:'แพร่', id: '19x36'},
  {title:'พระนครศรีอยุธยา', id: '19x78'},
  {title:'พะเยา', id: '19x35'},
  {title:'พังงา', id: '19x104'},
  {title:'พัทลุง', id: '19x106'},
  {title:'พิจิตร', id: '19x40'},
  {title:'พิษณุโลก', id: '19x38'},
  {title:'ภูเก็ต', id: '19x103'},
  {title:'มหาสารคาม', id: '19x58'},
  {title:'แม่ฮ่องสอน', id: '19x42'},
  {title:'มุกดาหาร', id: '19x63'},
  {title:'ยโสธร', id: '19x56'},
  {title:'ยะลา', id: '19x98'},
  {title:'ร้อยเอ็ด', id: '19x51'},
  {title:'ระนอง', id: '19x101'},
  {title:'ระยอง', id: '19x76'},
  {title:'ราชบุรี', id: '19x88'},
  {title:'ลพบุรี', id: '19x79'},
  {title:'เลย', id: '19x62'},
  {title:'ลำปาง', id: '19x33'},
  {title:'ลำพูน', id: '19x32'},
  {title:'ศรีสะเกษ', id: '19x68'},
  {title:'สกลนคร', id: '19x60'},
  {title:'สงขลา', id: '19x93'},
  {title:'สตูล', id: '19x100'},
  {title:'สมุทรปราการ', id: '19x75'},
  {title:'สมุทรสงคราม', id: '19x89'},
  {title:'สมุทรสาคร', id: '19x92'},
  {title:'สระแก้ว', id: '19x74'},
  {title:'สระบุรี', id: '19x80'},
  {title:'สิงห์บุรี', id: '19x82'},
  {title:'สุโขทัย', id: '19x45'},
  {title:'สุพรรณบุรี', id: '19x90'},
  {title:'สุราษฎร์ธานี', id: '19x94'},
  {title:'สุรินทร์', id: '19x53'},
  {title:'หนองคาย', id: '19x61'},
  {title:'หนองบัวลำภู', id: '19x49'},
  {title:'อ่างทอง', id: '19x81'},
  {title:'อำนาจเจริญ', id: '19x55'},
  {title:'อุดรธานี', id: '19x59'},
  {title:'อุตรดิตถ์', id: '19x46'},
  {title:'อุทัยธานี', id: '19x48'},
  {title:'อุบลราชธานี', id: '19x54'},
]
*/
