import React, { Component } from 'react'
import StepBar from '../../../hoc/StepBar/StepBar.js'
import {connect} from 'react-redux'
import UploadImageBoxes from '../../../components/Complaint/UploadImageBoxes/UploadImageBoxes.js'
import * as constant from '../../../utils/constant.js'
import './UploadImage.css'
import Loader from '../../../components/UI/Loader/Loader'
import * as action from '../../../store/actions/index.js' 

class UploadImagePage extends Component {

  onFileSelectedHandler = (event) => {
    const files = [...this.props.files]
    const index = files.findIndex(file => file.id === event.target.id)


    if (event.target.files[0] && event.target.files[0].type == 'application/pdf' && event.target.files[0].size > 4000000) {
      this.props.setError('กรุณาไฟล์ pdf ที่มีขนาดเล็กกว่า 4.0 MB')
      return
    } else if (event.target.files[0] && event.target.files[0].size > 3500000) {
      this.props.setError('กรุณาอัปโหลดรูปที่มีขนาดเล็กกว่า 3.5 MB')
      return
    } 

    files[index].file = event.target.files[0]
    this.props.saveImageFile(files)

    this.setState({
      files: files
    })
  }

  onRemoveImageHandler = (event) => {
    const files = [...this.props.files]
    const index = files.findIndex(file => file.id === event.target.id)
    files[index].file = null
    this.props.saveImageFile(files)
    this.setState({
      files: files
    })
  }

  onBack = () => {
    this.props.history.push('problem')
  }

  onNext = () => {
    this.props.history.push('request')
  }


  render() {

    return (
      <StepBar 
        header = {constant.UPLOAD_IMAGE_HEADER}
        onBack = {this.onBack} 
        onNext = {this.onNext}
        saveAble
        enable
      >
        <p className = "UploadImageTitle">
          {constant.UPLOAD_IMAGE_TITLE}
        </p>

        <UploadImageBoxes
          fileList = {this.props.files}
          onChange = {this.onFileSelectedHandler}
          onRemove = {this.onRemoveImageHandler}
        />
	      <Loader show = {this.props.loading} />
      </StepBar>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    files: state.complaint.imageFile,
    loading: state.complaint.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveImageFile: (file, index) => dispatch(action.saveImageFile(file, index)),
    setError: (message) => dispatch(action.setError(message))

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadImagePage)
