import React, {Component} from 'react'
import Ads from '../../../components/Complaint/Adspage/Adspage.js'
import * as constant from '../../../utils/constant.js'
import {connect} from 'react-redux'
import * as action from '../../../store/actions/index'
import Btnadspage from '../../../components/Complaint/Adspage/Btnadspage.js'
import {combineComplaintFields} from '../../../utils/utils.js'
import Loader from '../../../components/UI/Loader/Loader'


class AdsPage extends Component {
    state = {
      adsfields: this.props.adsfields,
      checkDonate: true,
      checkMember: true
    }

    componentDidUpdate() {
      if (this.props.redirect) {
        this.props.history.push('/dashboard')
      }
    }

    onadsFieldChange = (event) => {
      const key = event.target.id
      const value = event.target.value
      const adsfields = this.state.adsfields
      adsfields[key] = value
      this.setFields(adsfields)
    }
    
    setFields = (adsfields) => {
      let checkDonate = true
      let checkMember = true
  
      if (adsfields.donate !== constant.DONATE_OPTION[0] && adsfields.donate !== '') {
        checkDonate = false
      }
   
      if (adsfields.member !== constant.MEMBER_OPTION[0] && adsfields.member !== '') {
        checkMember = false
      }

      this.setState({
        adsfields,
        checkDonate,
        checkMember,
      })
    }

    onBack = () => {
      this.props.history.push('/dashboard')
    }

    onSave = () => {
      const fields = combineComplaintFields(this.props)
      this.props.submitAds(fields, this.props.sessionID)
    }
    
    render() {
      return (
        <div className="container font-gray">
          <div className="row">
            <div className="col-12 col-complaint">
              <p className="text-center">สอบถามข้อมูลเพิ่มเติม</p>
              <br/>
              <p className="text-center text-color">ขอความร่วมมือในการตอบเเบบสอบถามด้านล่างนี้</p>
              <p className="text-center text-color">ขอขอบพระคุณในความร่วมมือ</p>
              <Ads
                adsfields = {this.props.adsfields}
                onChange = {this.onadsFieldChange}
                checkDonate = {this.state.checkDonate}
                checkMember = {this.state.checkMember}   
                publicOptions = {constant.PUBLIC_OPTION}
                donateOptions = {constant.DONATE_OPTION}
                memberOptions = {constant.MEMBER_OPTION}
              />
            </div>
          </div>
          <Btnadspage backtomain={this.onBack} saveadspage={(fields) => this.onSave(fields)}/>
          <Loader show = {this.props.loading} />
        </div>
      )
    }
}

const mapStateToProps = (state) => {
  return {
    sessionID: state.session.sessionID,
    redirect: state.ads.saveRedirect,
    loading: state.ads.loading,
    adsfields: state.ads.adsFields,
    ...state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveForm: (adsfields) => dispatch(action.savePurchaseForm(adsfields)),
    submitAds: (fields, sessionID) => dispatch(action.submitAds(fields, sessionID))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdsPage)
