import React from 'react'
import {Link} from 'react-router-dom'

const navigationItem = (props) => {
  return (
    <li className="nav-item">
      <Link 
        to = {props.path} 
        className = "nav-link" 
        style = {{color: 'white'}}>
        {props.name}
      </Link>
    </li>
  )
}

export default navigationItem

