import React from 'react'
import './Accept.css'


const accept = (props) => {
  return (
    <div className='Accept'>
      <p>เงื่อนไขการร้องเรียนออนไลน์ ฝ่ายพิทักษ์สิทธิผู้บริโภค มูลนิธิเพื่อผู้บริโภค กรุณาอ่านและปฏิบัติตามข้อตกลงด้านล่างนี้โดยละเอียดและครบถ้วน เพื่อรักษาสิทธิประโยชน์ในการการร้องเรียนของท่าน</p>
      <br/>
      <p>ข้าพเจ้าได้รับทราบขณะร้องเรียนแล้วว่า</p>
      <p>
      1. มูลนิธิเพื่อผู้บริโภค (มพบ.) เป็นองค์กรสาธารณะประโยชน์ ด้านการคุ้มครองผู้บริโภค และเป็นหน่วยประจำจังหวัดกรุงเทพมหานคร ของสภาองค์กรของผู้บริโภค (สอบ.) มีฐานะเป็นผู้แทนของผู้บริโภค มีอำนาจดำเนินการให้ความคุ้มครองและพิทักษ์สิทธิของผู้บริโภค และอำนาจอื่น ๆ ตามที่ พ.ร.บ.การจัดตั้งสภาองค์กรของผู้บริโภค พ.ศ.2562 และข้อบังคับของสภาองค์กรของผู้บริโภคกำหนด
      </p>

      <p>
      2. ตามมาตรา 3 แห่งพระราชบัญญัติคุ้มครองผู้บริโภค พ.ศ. 2522 'ผู้บริโภค' คือ ผู้ใดก็ตามที่ซื้อ รับบริการ รับโฆษณา คำชักชวน จากผู้ประกอบธุรกิจเพื่อให้ซื้อสินค้าหรือรับบริการ โดยไม่คำนึงว่าจะมีการเสียค่าใช้จ่ายหรือไม่ 'ผู้ประกอบธุรกิจ' คือ ผู้ใดก็ตามที่ ขาย ผลิต สั่งหรือนำเข้ามาในประเทศเพื่อขาย รวมถึงผู้ซื้อมาขายต่อ หรือให้บริการ รวมถึงผู้ประกอบกิจการโฆษณา
      </p>
      <p>
      3. การร้องทุกข์ต่อมูลนิธิเพื่อผู้บริโภคไม่ทำให้อายุความสะดุดหยุดลง แต่อายุความในการฟ้องคดีจะหยุดลงชั่วคราวเมื่อได้มีการเจรจาระหว่างผู้ประกอบธุรกิจและผู้บริโภค จนกว่าจะมีฝ่ายใดยกเลิกการเจรจา
      </p>
      <p>
      4. หากไม่ได้รับการติดต่อกลับจากเจ้าหน้าที่รับเรื่องภายใน 7 วัน นับแต่วันที่ได้ร้องทุกข์ ผู้ร้องทุกข์สามารถติดตามเรื่องได้ผ่านช่องทาง
      <br/>❀ ที่อยู่ : 4/2 ซอยวัฒนโยธิน แขวงถนนพญาไท เขตราชเทวี กรุงเทพมหานคร 10400
      <br/>❀ เบอร์โทรศัพท์ : 0-2248-3734 ถึง 37
      <br/>❀ facebook : มูลนิธิเพื่อผู้บริโภค
      <br/>❀ Email : complaint@consumerthai.org
      <br/>❀ Line : @consumerthai
      </p>
      5. การร้องเรียนไม่ตัดสิทธิผู้ร้องเรียนที่จะดำเนินคดีตามกฎหมายด้วยตนเอง
      <p>
      6. ผู้ร้องเรียนต้องกรอกข้อมูลส่วนตัวและที่เกี่ยวข้องด้วยความสัตย์จริงให้ครบทุกข้อ เพื่อสิทธิประโยชน์ของท่าน
      </p>
      7. เรื่องราวและรายละเอียดการร้องเรียนให้ถือเป็นสิทธิของมูลนิธิฯ ที่จะเผยแพร่สู่สาธารณชนเพื่อประโยชน์ในการคุ้มครองผู้บริโภค โดยจะไม่เปิดเผยชื่อและรายละเอียดส่วนตัวของผู้ร้องเรียน หากไม่ได้รับความยินยอมจากผู้ร้องเรียนเป็นลายลักษณ์อักษร และขอสงวนสิทธิ์มิให้ผู้ใดนำไปใช้เพื่อประโยชน์ทางธุรกิจโดยเด็ดขาด
      <p>
      8. มูลนิธิเพื่อผู้บริโภคไม่เก็บค่าใช้จ่ายใด ๆ ในการร้องเรียน
      </p>

      <p>
      ข้าพเจ้าได้รับทราบว่า เรื่องร้องเรียนที่มูลนิธิเพื่อผู้บริโภค อาจพิจารณาให้ยุติเรื่อง มีดังนี้
      <br/>1. ผู้ร้องเรียนได้รับการแก้ไขปัญหาหรือชดเชยความเสียหายจากผู้ประกอบธุรกิจคู่กรณีเป็นที่เรียบร้อยแล้ว
      <br/>2. ผู้ร้องเรียนแจ้งขอถอนเรื่องด้วยตนเองเป็นลายลักษณ์อักษรหรือเป็นข้อความทางอิเล็กทรอนิกส์ก็ได้ เช่น อีเมล ไลน์ เป็นต้น
      <br/>3. ไม่สามารถติดต่อผู้ร้องเรียนได้เลย เป็นระยะเวลาต่อเนื่องเกิน 90 วันนับแต่วันที่มีการติดต่อครั้งสุดท้าย
      </p>

      <div className='Accept-checkbox'>
        <input 
          type="checkbox" 
          onChange={props.onAcceptCheckChange}
          checked={props.acceptChecked}/>
        <label>ยอมรับเงื่อนไข</label>
      </div>

    </div>
  )
}

export default accept
