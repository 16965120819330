import axios from '../axios-vtiger.js'

export  const updateState = (oldState, updatedProperties) => {
  return {
    ...oldState,
    ...updatedProperties
  }
}



