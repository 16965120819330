import * as actionTypes from './actionTypes'
import axios from '../../axios-vtiger'
import {sessionMiddleware, callGetSessionAPI} from '../actions/session.js'

export const checkLogin = () => {
  return dispatch => {
    const contactId = localStorage.getItem('contactId')
    if (contactId !== null) {
      const username = localStorage.getItem('username')
      const phone = localStorage.getItem('phone')
      const email = localStorage.getItem('email')
      dispatch(loginSuccess(username, contactId, phone, email))
    }
  }
}

export const login = (email, password, sessionID) => {
  return dispatch => {
    dispatch(loginStart())
    callLoginApi(dispatch, email, password, sessionID, 0)
  }
}

export const callLoginApi = (dispatch, email, password, sessionID, count) => {
  const path = '?function=CheckLogin&type=manual&email='+email+'&pass='+password+'&fbuser='+'&sessionid='+sessionID
  axios.get(path)
    .then(response => {
      if (response.data.code) {
        if (count < 1) {
          sessionMiddleware(dispatch, count, (session, count) => callLoginApi(dispatch, email, password, session, count))
          return
        } 
        dispatch(loginFail('Session Error'))
        return
      }

      if (!response.data.name || !response.data.contactid) {
        dispatch(loginFail('อีเมลหรือรหัสผ่าน ไม่ถูกต้อง'))
        return
      }
      dispatch(loginSuccess(response.data.name, response.data.contactid, response.data.phone, response.data.email))
    })
}

export const loginStart = () => {
  return {
    type: actionTypes.LOGIN_START
  }
}

export const loginSuccess = (username, contactId, phone, email) => {
  localStorage.setItem('contactId', contactId)
  localStorage.setItem('username', username)
  localStorage.setItem('phone', phone)
  localStorage.setItem('email', email)
  return {
    type: actionTypes.LOGIN_SUCCESS,
    username: username,
    contactId: contactId,
    phone: phone,
    email: email,
  }
}

export const loginFail = (error) => {
  return {
    type: actionTypes.LOGIN_FAIL,
    loginError: error
  }
}

export const forgetPasswordStart = (error) => {
  return {
    type: actionTypes.FORGET_PASSWORD_START,
    forgetPasswordError: error
  }
}


export const forgetPasswordSuccess = () => {
  return {
    type: actionTypes.FORGET_PASSWORD_SUCCESS
  }
}


export const forgetPasswordFail = (error) => {
  return {
    type: actionTypes.FORGET_PASSWORD_FAIL,
    forgetPasswordError: error,
  }
}

export const forgetPassword = (email, sessionID) => {
  return dispatch => {
    dispatch(forgetPasswordStart())
    callForgetPasswordAPI(dispatch, email, sessionID, 0)
  }
}

export const callForgetPasswordAPI = (dispatch, email, sessionID, count) => {
  const path = '?function=ForgotPass&email='+email+'&sessionid='+sessionID
  axios.get(path)
    .then(response => {
      if (response.data.code) {
        if (count < 1) {
          sessionMiddleware(dispatch, count, (session, count) => callForgetPasswordAPI(dispatch, email, session, count))
          return
        } 
        dispatch(forgetPasswordFail('Session Error'))
        return
      }

      if (response.data.message === 'true') {
        dispatch(forgetPasswordSuccess())
        return
      }
      dispatch(forgetPasswordFail())
    })
}

export const logout = () => {
  localStorage.removeItem('contactId')
  localStorage.removeItem('username')
  localStorage.removeItem('email')
  localStorage.removeItem('phone')
  return {
    type: actionTypes.LOGOUT
  }
}

export const forgetPasswordClear = () => {
  return {
    type: actionTypes.FORGET_PASSWORD_CLEAR,
  }
}

export const loginFacebook = (email, fbuser, fbid, sessionID) => {
  return dispatch => {
    dispatch(loginFacebookStart())
    callFacebookLogin(dispatch, email, fbuser, fbid, 'ad', 0)
  }
}

const callFacebookLogin = (dispatch, email, fbuser, fbid, sessionID, count) => {
  const path = '?function=CheckLogin&type=facebook&email='+email+'&pass&fbuser='+fbuser+'&fbid='+fbid+'&sessionid='+sessionID
  axios.get(path)
    .then(response => {

      if (response.data.code) {
        if (count < 1) {
          sessionMiddleware(dispatch, count, (session, count) => callFacebookLogin(dispatch, email, fbuser, fbid, session, count))
        } else {
          dispatch(loginFail('session error'))
        }
        return
      }

      if (response.data.response === 'failure') {
        dispatch(loginFacebookRegister(email, fbuser, fbid, sessionID))
        return 
      }
      dispatch(loginFacebookSuccess(response.data.name, response.data.contactid, response.data.phone, response.data.email))
    })
    .catch(error => {
      dispatch(loginFail('ไม่สามารถเชื่อมต่อกับ Facebook ได้'))
    })
}

const loginFacebookStart = () => {
  return {
    type: actionTypes.LOGIN_FACEBOOK_START
  }
}


export const loginFacebookSuccess = (username, contactId, phone, email) => {
  localStorage.setItem('contactId', contactId)
  localStorage.setItem('username', username)
  localStorage.setItem('phone', phone)
  localStorage.setItem('email', email)
  return {
    type: actionTypes.LOGIN_FACEBOOK_SUCCESS,
    username: username,
    contactId: contactId,
    phone: phone,
    email: email,
  }
}


export const loginFacebookRegister = (email, fbuser, fbid, sessionID) => {
  return dispatch => {
    const path = '?function=NewContact&fname='+fbuser+
      '&lname=&fbuser='+fbuser+
      '&pass&idcard&phone&email='+email+
      '&fbid='+fbid+
      '&sessionid='+sessionID
    axios.get(path)
      .then(response => {
        if (response.data.result === 'Duplicated') {
          dispatch(loginFail('User นี้เคยมีอยู่ในระบบแล้ว'))
          return
        }

        if (response.data.result === 'success') {
          dispatch(loginFacebookRegisterSuccess(response.data.name, response.data.Contactid))
          return 
        }
      })
      .catch(error => {
        dispatch(loginFail('ไม่สามารถเชื่อมต่อกับ Facebook ได้'))
      })
  }
}

export const loginFacebookRegisterSuccess = (username, contactId) => {
  localStorage.setItem('contactId', contactId)
  localStorage.setItem('username', username)
  return {
    type: actionTypes.LOGIN_FACEBOOK_REGISTER_SUCCESS,
    username: username,
    contactId: contactId,
  }
}

export const loginFacebookNull = () => {
  return {
    type: actionTypes.LOGIN_FACEBOOK_NULL
  }
}

export const updateContact = (contactId, email, phone, sessionID, callback) => {
  return dispatch => {
    dispatch(updateContactStart())
    callUpdateContactApi(dispatch, contactId, email, phone, sessionID, 0, callback)
  }
}

export const updateContactStart = () => {
  return {
    type: actionTypes.UPDATE_CONTACT_START,
  }
}

export const callUpdateContactApi = (dispatch, contactId, email, phone, sessionID, count, callback) => {
  const path = '?function=UpdateContact&email='+email+'&phone='+phone+'&contactid='+contactId+'&sessionid='+sessionID
  axios.get(path)
    .then(response => {
      if (response.data.code) {
        if (count < 1) {
          sessionMiddleware(dispatch, count, (session, count) => callUpdateContactApi(dispatch, contactId, email, phone, session, count))
          return
        } 
        dispatch(updateContactFail('Session Error'))
        return
      }

      if (!response.data.contactid) {
        dispatch(updateContactFail('UpdateContact error'))
        dispatch(setError(response.data.result))
        return
      }
      dispatch(updateContactSuccess(email, phone))
      callback()
    })
}

export const updateContactFail = (error) => {
  return {
    type: actionTypes.UPDATE_CONTACT_FAIL,
    updateContactError: error,
  }
}

export const updateContactSuccess = (email, phone) => {
  localStorage.setItem('phone', phone)
  localStorage.setItem('email', email)
  return {
    type: actionTypes.UPDATE_CONTACT_SUCCESS,
    phone: phone,
    email: email,
  }
}

export const setError = (message) => {
  return {
    type: actionTypes.SET_ERROR,
    error: {
      message: message
    }
  }
}
