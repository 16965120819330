import React from 'react'
import { Link} from 'react-router-dom'
import { Card, CardText } from 'material-ui/Card'
import RegisterFields from './RegisterFields/RegisterFields'

const RegisterCard = (props) => {

  const login = <CardText>หากมีบัญชีเเล้ว ? <Link to={'/signin'}>เข้าสู่ระบบ</Link></CardText>
  const loginPopup = <CardText>หากมีบัญชีเเล้ว ? <Link to={'#'} onClick={props.onLoginClicked}>เข้าสู่ระบบ</Link></CardText>

  return (
    <Card className="container text-center" >
      <div>
        <h2 className="card-heading font-text">{props.title}</h2>
        {props.registerError && <p className="error-message">{props.registerError}</p>}
        <RegisterFields
          {...props}
        />
        <div className="button-line">
          <button 
            onClick={props.onRegisterSubmitted}
            className="btn btn-primary"
          >สร้างบัญชีใหม่</button>
        </div>

      </div>
      {props.onLoginClicked ? loginPopup: login}
    </Card>
  )
}

export default RegisterCard
