import React from 'react'
import { ListGroup,ListGroupItem } from 'react-bootstrap'
const CategoryProblem = (props) => {
  return props.sub.map((result,index) =><div key={index}>
    <ListGroup>
      <ListGroupItem 
        className="text-left" 
        style={{backgroundColor: props.css(result)}} 
        id={result} 
        value={result} 
        onClick={props.clicked}>{result}</ListGroupItem>
    </ListGroup>
  </div>
  )
}
export default CategoryProblem
