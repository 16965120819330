import React from 'react'
import Ticket from './Ticket/Ticket.js'

const Tickets = (props) => {
  let ticketList = null
  if (props.ticketList) {
    ticketList = props.ticketList.map(ticket => {
      return (
        <Ticket
          key = {ticket.ticketid}
          id = {ticket.ticketid}
          ticket = {ticket}
          onClick = {() => {props.onClick(ticket.ticketid)}}
        />
      )
    })
  }
  return(
    <div>
      <div className="card card-makecomplaint herdercard">
        ติดตามเรื่องร้องเรียน
      </div>
      {ticketList}
    </div>
  )
}
export default Tickets
