import React from 'react'
import Login from '../../../containers/Login/Login'
import './LoginBox.css'


const loginBoxPopUp = (props) => {

  return (
    <div className='LoginBoxPopUp'>
      <Login
        title="กรุณาเข้าสู่ระบบก่อนเริ่มการร้องเรียนเพื่อเป็นประโยชน์ต่อการใช้สิทธิของท่าน"
        onSignUpClicked={props.onSignUpClicked}
        callback={props.callback}
        isPopup
      />
    </div>
  )
}

export default loginBoxPopUp