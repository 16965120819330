import React, {Component} from 'react'
import {connect} from 'react-redux'
import StepBar from '../../../hoc/StepBar/StepBar.js'
import Purchase from '../../../components/Complaint/Purchase/Purchase.js'
import * as action from '../../../store/actions/index'
import Loader from '../../../components/UI/Loader/Loader'
import * as constant from '../../../utils/constant.js'
import {getISODateString} from '../../../utils/utils.js'
import { Thumbnail } from 'react-bootstrap'



class PurchasePage extends Component {

  componentDidMount() {

    let fields = this.props.fields

    if (this.props.purchaseList.length === 0) {
      this.props.getPurchaseList(this.props.sessionID)
    } 

    if (this.props.relationList.length === 0) {
      this.props.getRelationList(this.props.sessionID)
    } 

    if (fields.sufferer === '') {
      fields.sufferer = this.props.username
    }

    if (fields.payment === '') {
      fields.payment = constant.PAYMENT_OPTION[0]
    }

    if (fields.delegate && fields.relation === '') {
      fields.relation = this.props.relationList[0] || ''
    }

    this.setFields(fields)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.purchaseList.length === 0 
      && nextProps.purchaseList.length !== 0 
      && this.props.fields.purchase === '') {
      nextState.fields.purchase = nextProps.purchaseList[0]
      this.setFields(nextState.fields)
    }
    return true
  }

  state = {
    fields: this.props.fields,
    checkWeb: false,
    checkStore: false,
    errors: {
      sufferer: false,
      productname: false,
      purchasedate: false,
      payment : false,
      productprice : false,
      purchase : false,
      website : false,
      store : false,
      relation: false,
    }
  }

  onBack = () => {
    this.props.saveForm(this.state.fields)
    if (this.props.isUpdate) { 
      this.props.history.push('dashboard')
    } else {
      this.props.history.push('category')
    }
  }
  
  onNext = () => {
    if (this.checkFieldsHaveNoError()) {
      this.props.saveForm(this.state.fields)
      this.props.history.push('/problem')
    }
  }

  onDelegateToggleClicked = (value) => {
    const fields = this.state.fields
    fields.delegate = value

    if (value) {
      fields.relation = this.props.relationList[0] || ''
    } else {
      fields.relation = ''
    }

    this.setState({
      fields,
    })
  }

  onFieldChange = (event) => {
    const key = event.target.id
    const value = event.target.value
    const fields = this.state.fields
    fields[key] = value

    this.setFields(fields)
  }

  setFields = (fields) => {
    let checkStore = false
    let checkWeb = false

    if (fields.purchase === window.env.purchaseStore1 
      || fields.purchase === window.env.purchaseStore2 ) {
      checkStore = true
    }else {
      fields.store = ''
    }

    if (fields.purchase === window.env.purchaseWeb1
      || fields.purchase === window.env.purchaseWeb2) {
      checkWeb = true
    }else {
      fields.website = ''
    }

    this.setState({
      fields,
      checkStore,
      checkWeb,
    })
  }

  onDateChange = (event, date) => {
    const fields = this.state.fields
    fields.purchasedate = getISODateString(date)

    this.setState({
      fields
	  })
  }

  checkFieldsHaveNoError = () => {
    let errors = {}
    this.setState({
      errors: {},
    })

    if (!this.state.fields.sufferer || this.state.fields.sufferer === '') {
      errors.sufferer = true
    }

    if(this.state.fields.productname === '')
    {
      errors.productname = true
    }

    if(this.state.fields.payment === ''){
      errors.payment = true
    }

    if(this.state.fields.productprice === ''){
      this.setState({
        fields: {
          ...this.state.fields,
          productprice: 0
        }
      })
    }

    if(this.state.fields.purchase === ''){
      errors.purchase = true
    }
    
    if(this.state.checkStore === true && this.state.fields.store === ''){
      errors.store = true
    }
    if(this.state.checkWeb === true && this.state.fields.website === ''){
      errors.website = true
    }

    if (JSON.stringify(errors) !== '{}') {
      this.setState({
        errors,
      })
      return false
    }
    return true
  }

  render() {

    return (
      <StepBar 
        header = {constant.PURCHASE_HEADER}
        onBack = {this.onBack} 
        enable
        onNext = {this.onNext}
        saveAble
      >
        <p>
          <span className="text-color">{constant.PURCHASE_TITLE}</span> 
          {this.props.accountname}
        </p>
        <Purchase 
          fields = {this.state.fields} 
          errors={this.state.errors} 
          onChange = {this.onFieldChange}  
          onDateChange = {this.onDateChange}
          checkWeb = {this.state.checkWeb}
          checkStore = {this.state.checkStore}
          paymentOptions = {constant.PAYMENT_OPTION}
          purchaseOptions = {this.props.purchaseList}
          relationOptions = {this.props.relationList}
          onDelegateToggleClicked = {this.onDelegateToggleClicked}
        />
	      <Loader show = {this.props.loading} />
      </StepBar>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    sessionID: state.session.sessionID,
    subcate1: state.complaint.subcate1,
    username: state.user.username,
    fields: state.complaint.purchaseFields,
    loading: state.complaint.loading,
    purchaseList: state.complaint.purchaseList,
    accountname: state.complaint.accountname,
    isUpdate: state.complaint.isUpdate,
    relationList: state.complaint.relationList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClearSubcate1: () => dispatch(action.clearSubcate1()),
    saveForm: (fields) => dispatch(action.savePurchaseForm(fields)),
    getPurchaseList: (sessionID) => dispatch(action.getPurchaseList(sessionID)),
    getRelationList: (sessionID) => dispatch(action.getRelationList(sessionID)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchasePage)
