import React, {Component} from 'react'
import {connect} from 'react-redux'
//import Category from '../../../components/Form/FormType/FormType.js'
import Loader from '../../../components/UI/Loader/Loader.js'
import StepBar from '../../../hoc/StepBar/StepBar.js'
import Problem from '../../../components/Complaint/Problem/Problem.js'
import * as action from '../../../store/actions/index'
import * as constant from '../../../utils/constant.js'

class ProblemPage extends Component {

  state = {
    fields: this.props.fields,
    errors: {
      desc: false
    }
  }

  componentDidMount() {

    if (this.props.problemList.length === 0) {
      this.props.getProblemList(this.props.subcate2)
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.problemList.length === 0 
      && nextProps.problemList.length !== 0
      && this.props.fields.problem === '') {
      nextState.fields.problem = nextProps.problemList[0]
    }
    return true
  }


  onBack = () => {
    this.props.saveForm(this.state.fields)
    this.props.history.push('/purchase')
  }

  onNext = () => {

    const fields = this.state.fields
    if(this.state.fields.damage == ''){
      fields.damage = '0'
    }

    if (!this.isLimitedDesc(this.state.fields.desc)) {
      this.props.saveForm(fields)
      this.props.history.push('/upload_image')
    }
  }

  onFieldChange = (event) => {
    const key = event.target.id
    const value = event.target.value
    const fields = this.state.fields

    fields[key] = value

    this.setState({
      fields
    })
  }

  isLimitedDesc = (desc) => {
    const err = desc.length > 1000
    this.setState({
      errors: {
        ...this.state.errors,
        desc: err
      }
    })
    return err
  }

  
  
  render() {

    return (
      <StepBar 
        header = {constant.PROBLEM_HEADER}
        onBack = {this.onBack} 
        onNext = {this.onNext}
        saveAble
        enable
      >
        <p>
          <span className="text-color">{constant.PROBLEM_TITLE}</span> 
          {this.props.accountname}
        </p>
        <Problem
          fields = {this.state.fields}
          problemList = {this.props.problemList}
          onChange = {this.onFieldChange}
          errors = {this.state.errors}
        />
	      <Loader show = {this.props.loading} />
      </StepBar>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    fields: state.complaint.problemFields,
    subcate2: state.complaint.subcate2,
    problemList: state.complaint.problemList ,
    loading: state.complaint.loading,
    accountname: state.complaint.accountname
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getProblemList: (subcate2) => dispatch(action.getProblemList(subcate2)),
    saveForm: (fields) => dispatch(action.saveProblemForm(fields)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProblemPage)
