import React, {Component} from 'react'
import StepBar from '../../../hoc/StepBar/StepBar.js'
import Loader from '../../../components/UI/Loader/Loader.js'
import Accept from  '../../../components/Complaint/Accept/Accept.js'

class AcceptPage extends Component {


  onBack = () => {
    this.props.history.push('dashboard')
  }

  onNext = () => {
    this.props.history.push('search')
  }

  state = {
    onNextEnable: true,
    acceptChecked: false,
  }

  onAcceptCheckChange = (event) => {
    this.setState({
      acceptChecked: event.target.checked
    })
  }

  render() {
    return (
      <StepBar 
        header = ""
        onBack = {this.onBack} 
        enable = {this.state.acceptChecked}
        onNext = {this.onNext}
      >
        <Accept
          acceptChecked={this.state.acceptChecked}
          onAcceptCheckChange={this.onAcceptCheckChange}
        />
        <Loader show = {this.props.apiLoading} />
      </StepBar>
    )
  }
}

export default AcceptPage