import React, { Component } from 'react'
import {connect} from 'react-redux'
import StepBar from '../../../hoc/StepBar/StepBar.js'
import Request from '../../../components/Complaint/Request/Request.js'
import Loader from '../../../components/UI/Loader/Loader'
import * as action from '../../../store/actions/index'
import * as constant from '../../../utils/constant.js'

class RequestPage extends Component {

  state = {
    enable: false,
  }

  componentDidMount() {
    if (this.props.requestList.length === 0) {
      this.props.onGetRequestList(this.props.sessionID)
    }

    if (this.props.request) {
      this.setState({
        enable: true
      })
    }
  }

  onBack = () => {
    this.props.history.push('/upload_image')
  }
  
  onNext = () => {
    this.props.history.push('/summary')
  }

  handleClick = (event) => {

    if (this.props.request === event.target.value) {
      this.setState({
        enable: false
      })
      this.props.onSaveNewRequest(null)
    } else {
      this.setState({
        enable: true
      })
      this.props.onSaveNewRequest(event.target.value)
    }
  }


  css = (name) =>{
    if (this.props.request === name) {
      return 'rgb(0, 188, 212)'
  	  }
  	  return '#ffffff'
  }

  render() {

    return (
      <StepBar 
        header = {constant.REQUEST_HEADER}
        onBack = {this.onBack} 
        enable = {this.state.enable}
        onNext = {this.onNext}
        saveAble
      >
        <p>
          <span className="text-color">{constant.REQUEST_TITLE}</span> 
          {this.props.accountname}
        </p>
        <Request
          requestList = {this.props.requestList}
          background = {this.css}
          onClick = {this.handleClick}
        />
	      <Loader show = {this.props.loading} />
      </StepBar>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    sessionID: state.session.sessionID,
    requestList: state.complaint.requestList,
    request: state.complaint.request,
    accountname: state.complaint.accountname,
    loading: state.complaint.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSaveNewRequest: (request) => dispatch(action.saveNewRequest(request)),
    onGetRequestList: (sessionID) => dispatch(action.getRequestList(sessionID))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestPage)
